import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonReorderGroup,
  IonReorder,
  IonIcon,
} from "@ionic/react";
import { searchOutline, closeCircleOutline } from "ionicons/icons";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { ItemReorderEventDetail } from "@ionic/core";

import { usePlaylists } from "../data/store/usePlaylists";
import { useProfile } from "../data/store/useProfile";
import { useArtists } from "../data/store/useArtists";

import Content from "../components/Content";
import AnimatedSearchBar from "../components/AnimatedSearchBar";
import WarningCard from "../components/WarningCard";

import { Playlist } from "../models/Playlists";
import HostConfig from "../HostConfig";
import { usePageTitle } from "../util/sugar";
import { changeArrayItemsOrder } from "../util/sugar";
import {
  warningGlobalPlaylistText,
  warningPersonalPlaylistText,
} from "../util/constants";
import isEmpty from "lodash.isempty";
import get from "lodash.get";

interface PlaylistsProps {}

const Playlists: React.FC<PlaylistsProps> = () => {
  const {
    status: playlistStatus,
    all,
    playlists,
    fetchAll,
    updatePlaylist,
  } = usePlaylists();
  const { ownerId, authorId, changeUserPlaylistOrder } = useProfile();
  const { status, retrieve, fetch } = useArtists((state) => state);

  const [playlistState, setPlaylistState] = useState("personalPlaylists");
  const [currentPlaylists, setCurrentPlaylists] = useState<Playlist[]>([]);
  const [query, setQuery] = useState("");
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const artist = retrieve(authorId || "");
  const playlistsOrder = (artist && artist.playlistsOrder) || {};
  const { personalPlaylists, globalPlaylists } = playlistsOrder;
  const warningPlaylistText =
    playlistState === "personalPlaylists"
      ? warningPersonalPlaylistText
      : warningGlobalPlaylistText;

  usePageTitle("Playlists");

  const togglePlaylistVisible = async (playlist: Playlist) => {
    const freshPlaylist = { ...playlist, isPrivate: !playlist.isPrivate };
    await updatePlaylist(freshPlaylist);
  };

  const togglePlaylistState = ({ detail: { value } }: any) => {
    setPlaylistState(value);
  };

  const onSortPlaylists = (event: CustomEvent<ItemReorderEventDetail>) => {
    let items = [...currentPlaylists];
    items = event.detail.complete(items);

    const sortedItems = items.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    changeUserPlaylistOrder(playlistState, sortedItems);
    fetch(authorId || "");
  };

  useLayoutEffect(() => {
    fetchAll().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allPersonalPlaylist = all.filter((item) => item.ownerId === ownerId);
    const allGlobalPlaylists = all.filter(
      (item) => item.ownerId !== ownerId && !item.isPrivate
    );

    if (playlistState === "personalPlaylists") {
      setCurrentPlaylists(
        changeArrayItemsOrder(personalPlaylists, playlists, allPersonalPlaylist)
      );
    } else {
      setCurrentPlaylists(
        changeArrayItemsOrder(globalPlaylists, playlists, allGlobalPlaylists)
      );
    }
  }, [
    playlistState,
    artist,
    personalPlaylists,
    all,
    ownerId,
    playlists,
    globalPlaylists,
  ]);

  return (
    <Content>
      <IonRow>
        <IonCol sizeMd="12" sizeLg="8" offsetLg="2">
          <IonRow
            style={{ display: "flex", flexWrap: "nowrap", zIndex: 10 }}
            class="ion-align-items-center"
          >
            <IonSegment value={playlistState} onIonChange={togglePlaylistState}>
              <IonSegmentButton value="personalPlaylists">
                Personal Playlists
              </IonSegmentButton>
              <IonSegmentButton value="globalPlaylists">
                Global Playlists
              </IonSegmentButton>
            </IonSegment>

            <IonButton
              size="small"
              style={{ zIndex: 10 }}
              onClick={() => setIsSearchEnabled((prevState) => !prevState)}
            >
              <IonIcon
                icon={isSearchEnabled ? closeCircleOutline : searchOutline}
              />
            </IonButton>
          </IonRow>

          <AnimatedSearchBar isOpen={isSearchEnabled} onChange={setQuery} />

          <IonReorderGroup disabled={false} onIonItemReorder={onSortPlaylists}>
            {isEmpty(currentPlaylists) ? (
              <WarningCard text={warningPlaylistText} />
            ) : (
              currentPlaylists
                .filter((item) => item.title.toLowerCase().includes(query))
                .map((playlistItem: Playlist, index: number) => {
                  const playlist = playlists[playlistItem.id];

                  return (
                    <IonItem
                      detail
                      lines="none"
                      key={index}
                      routerLink={"/playlist/" + playlist.id}
                    >
                      <IonReorder slot="start" />
                      <IonAvatar slot="start">
                        <IonImg
                          src={get(playlist, "art.src", `${HostConfig.icon}`)}
                        />
                      </IonAvatar>
                      <IonLabel slot="start">{playlist.title}</IonLabel>

                      {playlist.authorId === authorId && (
                        <IonButton
                          slot="end"
                          color={playlist.isPrivate ? "primary" : "secondary"}
                          onClick={async (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            await togglePlaylistVisible(playlist);
                          }}
                        >
                          <IonIcon
                            color="dark"
                            icon={
                              playlist.isPrivate ? eyeOffOutline : eyeOutline
                            }
                          />
                        </IonButton>
                      )}
                    </IonItem>
                  );
                })
            )}
          </IonReorderGroup>

          <IonButton
            expand="full"
            color="primary"
            routerLink="/playlist-create"
          >
            Create your playlist
          </IonButton>
          <IonLoading
            isOpen={playlistStatus === "fetching" || status === "fetching"}
          />
        </IonCol>
      </IonRow>
    </Content>
  );
};

export default React.memo(Playlists);
