import { firestore } from "firebase/app";
import { ArtistId } from "../models/Artist";
import { FreshNotice, Notice, NoticeId } from "../models/Notice";
import isEmpty from "lodash.isempty";
import isArray from "lodash.isarray";
import { ArtistService } from "./ArtistService";
import ObserverService from "./ObserverService";

export class NotificationService {
  static askPermission() {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      return;
    } else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
    } else {
      // Otherwise, we need to ask the user for permission
      Notification.requestPermission();
    }
  }

  static remove = (noticeId: NoticeId) => {
    return new Promise((resolve, reject) => {
      if (noticeId === "") {
        return;
      }

      const db = firestore();
      const noticeRef = db.collection("notifications").doc(noticeId);
      noticeRef
        .get()
        .then((noticeDoc) => {
          if (noticeDoc.exists) {
            const notice = noticeDoc.data() as Notice;
            db.collection("notifications")
              .where("to", "==", notice.to)
              .where("link", "==", notice.link)
              .get()
              .then((x) =>
                Promise.all(x.docs.map((x) => x.ref.delete())).then(resolve)
              );
          }
        })
        .catch(reject);
    });
  };

  static removeOneNotification = (noticeId: NoticeId) => {
    return new Promise((resolve, reject) => {
      if (isEmpty(noticeId)) return;

      const db = firestore();
      db.collection("notifications")
        .doc(noticeId)
        .delete()
        .then(resolve)
        .catch(reject);
    });
  };

  static removeNotificationsForDeletedMessage = (
    noticeId: NoticeId | NoticeId[]
  ) => {
    return new Promise(async () => {
      if (isEmpty(noticeId)) return;

      if (isArray(noticeId)) {
        // Delete the original comment notifications
        for (const id of noticeId) {
          await NotificationService.removeOneNotification(id);
        }
      } else {
        // Delete the original comment notification
        await NotificationService.removeOneNotification(noticeId);
      }
    });
  };

  static clear = (artistId: ArtistId) => {
    return new Promise<void>((resolve, reject) => {
      console.log("clear notification");
      const db = firestore();
      artistId &&
        db
          .collection("notifications")
          .where("to", "==", artistId)
          .get()
          .then((x) => {
            x.docs.forEach((x) => x.ref.delete());
          })
          .then(() => {
            resolve();
          })
          .catch(reject);
    });
  };

  static post = (notice: FreshNotice) => {
    return new Promise<Notice>((resolve, reject) => {
      console.log("post notification");
      const db = firestore();
      db.collection("notifications")
        .add(notice)
        .then((noticeRef) => {
          noticeRef.get().then((noticeDoc) => {
            const notice = noticeDoc.data() as Notice;

            resolve({
              ...notice,
              id: noticeDoc.id,
            });
          });
        })
        .catch(reject);
    });
  };

  static update = async (notice: FreshNotice, noticeId: NoticeId) => {
    new Promise<Notice>((resolve, reject) => {
      const db = firestore();
      db.collection("notifications")
        .doc(noticeId)
        .set({ ...notice })
        .catch(reject);
    });
  };

  static observe = (
    artistId: ArtistId,
    onNotification: (notices: Notice[]) => void
  ) => {
    NotificationService.askPermission();

    if (ObserverService.please().exist("notifications")) {
      return;
    }

    const db = firestore();
    const watcher = db
      .collection("notifications")
      .where("to", "==", artistId)
      .onSnapshot((notifications) => {
        let notices: Notice[] = [];

        for (let serverNotice of notifications.docs) {
          let notice = serverNotice.data() as Notice;
          notice.id = serverNotice.id;
          if (notice === undefined) {
            break;
          }
          notices.push(notice);
        }

        onNotification(notices);

        ObserverService.please().add("notifications", watcher);

        if (!("Notification" in window)) {
          return;
        }

        const isHere = notices.find((x) =>
          document.location.pathname.includes(x.link)
        );

        if (isHere) {
          NotificationService.remove(isHere.id);
        }

        //If we are already at the location of the notification
        //don't do anything
        if (Notification.permission === "granted" && notices.length > 0) {
          const notice = notices[0];
          notice &&
            ArtistService.fetch(notice.from).then((artist) => {
              if (window.location.href.includes(notice.link)) {
                return;
              }

              const { avatar, alias, wall } = artist;
              const topNotice = new Notification(alias, {
                image: wall,
                body: notice.text,
                icon: avatar,
              });
              topNotice.onclick = () => {
                NotificationService.remove(notice.id).then(() => {
                  window.open(
                    "https://song-share.org/" + notice.link,
                    "_blank"
                  );
                });
              };
            });
        }
      });
  };

  static fetch = (notificationId: NoticeId) => {
    return new Promise((resolve, reject) => {
      const db = firestore();
      db.collection("notifications")
        .doc(notificationId)
        .get()
        .then((notification) => {
          const currentNotification = notification.data();

          resolve(currentNotification);
        })
        .catch(reject);
    });
  };

  static stopObserving = () => {
    //console.log("Stop thread observer");
    ObserverService.please().remove("notifications");
  };
}
