import { IonApp, IonMenu, IonPage, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ArchiveDetail from "./components/ArchiveDetail";
import Archives from "./components/Archives";
import { AssignmentSongs } from "./components/AssignmentSongs";
import ContextModals from "./components/ContextModals";
import ConvertPage from "./components/ConvertPage";
import { CreatePlaylist } from "./components/CreatePlaylist";
import Favorites from "./components/Favorites";
import Menu from "./components/Menu";
import NowPlayingBottomBar from "./components/NowPlayingBottomBar";
import Playlist from "./components/Playlist";
import Albums from "./pages/Albums";
import Album from "./pages/Album";
import CreateAlbum from "./pages/CreateAlbum";
import { SongDelete } from "./components/SongDelete";
import SongEditForm from "./components/SongEditForm";
import TopToolbar from "./components/TopToolbar";
import TransmitForm from "./components/TransmitForm";
import { useArtists } from "./data/store/useArtists";
import { useAssignments } from "./data/store/useAssignments";
import { useConfig } from "./data/store/useConfig";
import { useFavorites } from "./data/store/useFavorites";
import { useNotifications } from "./data/store/useNotifications";
import { useProfile } from "./data/store/useProfile";
import { useRoles } from "./data/store/useRoles";
import { defaultConfiguration } from "./models/Configuration";
import Account from "./pages/Account";
import Activity from "./pages/Activity";
import Admin from "./pages/Admin";
import ArtistDetail from "./pages/ArtistDetail";
import ArtistList from "./pages/ArtistList";
import Conversations from "./pages/Conversations";
import Groups from "./pages/Groups";
import GroupSongsPage from "./pages/GroupSongsPage";
import Invitation from "./pages/Invitation";
import Issue from "./pages/Issue";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Mission from "./pages/Mission";
import OldGroupSongsPage from "./pages/OldGroupSongsPage";
import PlaylistDetail from "./pages/PlaylistDetail";
import Playlists from "./pages/Playlists";
import SearchPage from "./pages/SearchPage";
import SongDetail from "./pages/SongDetail";
import SongsPage from "./pages/SongsPage";
import TagsPage from "./pages/TagsPage";
import Visualizer from "./pages/Visualizer";
/* Theme variables */
import "./theme/variables.css";
import { useBodyClass } from "./util/sugar";
import SortSongs from "./components/SortSongs";
import GroupSongsSheet from "./components/SongsSheet";
import UnfilteredSongsPage from "./pages/UnfilteredSongsPage";
import { fixDateForSong } from "./services/MigrationService";
import { SearchService } from "./services/SearchService";

const SadFamApp: React.FC = () => {
  const { darkMode, observeAuthentication } = useProfile();
  const { observeDirectory, fetch } = useArtists();
  const observeConfig = useConfig((x) => x.observe);
  const observeRoles = useRoles((r) => r.observe);
  const observeAssignments = useAssignments((r) => r.observe);
  const initializeFavorites = useFavorites((r) => r.initializeFavorites);

  //  If you want to add or delete field or field value in db
  // const addSongId = () => {
  //   const db = firestore();
  //   db.collection("threads")
  //     .get()
  //     .then(function (querySnapshot) {
  //       querySnapshot.forEach(async function (doc) {
  //         const docRef = doc.data();
  //         await db
  //           .collection("songs")
  //           .doc(doc.id)
  //           .set({ commentsNumber: docRef.comments.length }, { merge: true });
  //         // doc.data() is never undefined for query doc snapshots
  //         console.log(doc.id, " => ", doc.data());
  //       });
  //     });
  // };

  const { observe, clear } = useNotifications();

  useBodyClass(darkMode ? "dark" : "light");
  useEffect(() => {
    fixDateForSong()
    console.log(
      "Booting Song Engine " + defaultConfiguration.version + " Milkdromeda 🌌"
    );
    observeAuthentication((artistId, ownerID) => {
      observeDirectory();
      observeRoles(ownerID);
      observeConfig(ownerID, artistId, observeAssignments);
      observe(artistId);
      fetch(artistId);
      initializeFavorites(ownerID);
      // SearchService.please().indexDatabase();
      // firestore().collection("version").doc("current").get().then((snap) => {
      //   const { identifier } = (snap.data() as any)
      //   if (identifier !== defaultConfiguration.version) {
      //     window.location.replace(window.location.href);
      //   }
      // })
    }, clear);
    // eslint-disable-next-line
  }, [
    clear,
    fetch,
    initializeFavorites,
    observe,
    observeAuthentication,
    observeConfig,
    observeDirectory,
    observeRoles,
  ]);

  return (
    <IonApp className={`${darkMode ? "dark-theme" : ""}`}>
      <IonReactRouter>
        <Menu />
        <Playlist />
        <ContextModals />
        <IonMenu side="end" menuId="sortSongs" type="overlay" contentId="main" class="filter-menu">
          <SortSongs />
        </IonMenu>

        <IonPage>
          <TopToolbar />
          <IonRouterOutlet animated={false} id="main">
            <Switch>
              <Route path="/sessions" component={Groups} exact={true} />
              <Route path="/" component={UnfilteredSongsPage} exact={true} />
              <Route path="/assignments" component={Groups} exact={true} />
              <Route path="/session-songs/:id" component={GroupSongsPage} />
              <Route path="/session-sheet/:id" component={GroupSongsSheet} />
              <Route
                path="/old-session-songs/:id"
                component={OldGroupSongsPage}
              />
              <Route
                path="/assignment-songs/:id/:assignmentId"
                component={AssignmentSongs}
              />
              <Route path="/convert" component={ConvertPage} />
              <Route path="/artists" component={ArtistList} exact={true} />
              <Route
                path={["/artist/:id", "/artists/:id"]}
                component={ArtistDetail}
                exact={true}
              />
              <Route
                path={["/song/:id", "/songs/:id"]}
                component={SongDetail}
              />
              <Route path={"/delete-song/:id"} component={SongDelete} />
              <Route path={"/edit-song/:id"} component={SongEditForm} />
              {/*<Route path="/revise-song/:id" component={ReviseSong} />*/}
              {/*<Route path="/song-revisions/:id" component={SongRevisions} />*/}
              <Route path="/transmit" component={TransmitForm} exact={true} />
              <Route
                path="/conversations"
                component={Conversations}
                exact={true}
              />
              <Route path="/notices" component={Activity} exact={true} />
              <Route path="/account" component={Account} />
              {/*<Route path="/terms" component={Terms} />*/}
              {/*<Route path="/privacy-policy" component={Policy} />*/}
              {/*<Route path="/community-guidelines" component={Guidelines} />*/}
              <Route path="/login" component={Login} />
              <Route path="/issue/:collection/:id" component={Issue} />
              <Route path="/admin" component={Admin} />
              <Route path="/search" component={SearchPage} />
              <Route path="/visualizer" component={Visualizer} />
              <Route path="/archives" component={Archives} exact={true} />
              <Route path="/archives/:id" component={ArchiveDetail} />
              <Route path="/archive/:id" component={ArchiveDetail} />
              <Route path="/albums" component={Albums} />
              <Route path="/album/:id" component={Album} />
              <Route path="/album-create" component={CreateAlbum} />
              <Route path="/playlists" component={Playlists} />
              <Route path="/playlist/:id" component={PlaylistDetail} />
              <Route path="/playlist-create" component={CreatePlaylist} />
              <Route path="/favorites" component={Favorites} />
              <Route path="/invitation/" component={Invitation} />
              <Route path="/mission" component={Mission} />
              <Route path="/favorites" component={Favorites} />
              <Route path="/logout" component={Logout} />
              <Route path="/tag/:id" component={TagsPage} />
              <Redirect to="/" />
            </Switch>
          </IonRouterOutlet>
          <NowPlayingBottomBar />
        </IonPage>
      </IonReactRouter>
    </IonApp>
  );
};

export default SadFamApp;
