import { IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react'
import React, { useEffect } from 'react'

import Content from '../components/Content'
import PleaseLoginCard from '../components/PleaseLoginCard'
import RequestAccessCard from '../components/RequestAccessCard'
import SongList from '../components/SongList'

import { useConfig } from '../data/store/useConfig'
import { useProfile } from '../data/store/useProfile'
import { useSongs } from '../data/store/useSongs'

import isEmpty from 'lodash.isempty'
import CompactSongList from '../components/CompactSongList'
import { CompactToggle } from '../components/CompactToggle'
import '../components/styles.scss'
import { usePageTitle } from '../util/sugar'
import useWindowSize from '../util/useWindowSize'

const UnfilteredSongsPage: React.FC = () => {
  const { authorId, status: userStatus, compact } = useProfile()
  const { fetchSome, status, initialFetchStatus, timeline } = useSongs()
  const { isAlumni, activeMemberships } = useConfig()
  const { width } = useWindowSize()

  const membership = activeMemberships()
  const unauthenticated = userStatus === 'unauthenticated'
  const authenticated = userStatus === 'authenticated'
  const songStatus = !isEmpty(membership) ? status : 'idle'
  const lg = width > 767

  usePageTitle('Songs')

  const handleFetchMore = () => {
    fetchSome(membership)
  }

  useEffect(() => {
    if (
      authenticated &&
      initialFetchStatus === 'initial' &&
      !isEmpty(membership)
    ) {
      fetchSome(membership)
    }
  }, [authenticated, initialFetchStatus, membership, fetchSome])

  if (isAlumni() === false) {
    return (
      <Content>
        <RequestAccessCard authorId={authorId} />
      </Content>
    )
  } else if (unauthenticated) {
    return (
      <Content>
        <PleaseLoginCard action="View Songs" />
      </Content>
    )
  }

  return (
    <Content>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonCardContent
              style={{
                padding: '5px',
                borderRadius: '8px',
                margin: '0 16px',
              }}
            >
              <CompactToggle />
            </IonCardContent>

            {isEmpty(membership) && (
              <RequestAccessCard message="You have no active classes" />
            )}

            {compact ? (
              <CompactSongList
                songRefs={timeline}
                fetchMore={handleFetchMore}
                status={songStatus}
              />
            ) : (
              <SongList
                status={songStatus}
                fetchMore={handleFetchMore}
                songRefs={timeline}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  )
}

export default UnfilteredSongsPage
