import React, { useState } from 'react';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { ArtistId } from '../models/Artist';
import { MediaReference } from '../models/Song';
import { TransmissionService } from '../services/TransmissionService';

interface StateProps {
    ownerId?: ArtistId
}
interface OwnProps {
    message?: string
    collection: string
    onComplete: (media: MediaReference) => void
}
interface DispatchProps {
}
interface UploaderProps extends OwnProps, StateProps, DispatchProps { }

const Uploader: React.FC<UploaderProps> = ({ ownerId, collection, onComplete, message }) => {
    const [fileExtension, setFileExtension] = useState("");
    // specify upload params and url for your files
    const getUploadParams = async () => {
        const { fields, uploadUrl, fileUrl } = await TransmissionService.GeneratePreSignedURL(ownerId || "anonymous", collection, fileExtension)
        return { fields, meta: { fileUrl }, url: uploadUrl }
    }
    // called every time a file's `status` changes
    const handleChangeStatus = (file: IFileWithMeta, status: StatusValue) => { //console.log(status, data.meta, data.file)
        const fileName = file && file.meta && file.meta.name;
        if (fileName) {
            const extension = fileName.split(".").pop();
            if (extension) {
                setFileExtension(extension);
            }
        }
        const type = file.file.type
        const src = (file.meta as any).fileUrl;
        const media = { src, type } as MediaReference;
        if (status === 'done') onComplete(media);
    }


    return (
        <Dropzone
            maxFiles={1}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            canCancel={false}
            multiple={false}
            maxSizeBytes={104857600}
            inputContent={(message && message) || "select an mp3"}
            accept={collection === "songs" ? "audio/*" : "image/*"}
        />
    )
}


export default Uploader;
