import React from "react";
import {
  IonImg,
  IonRow,
  IonCard,
  IonText,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import { Album } from "../models/Albums";

interface AlbumHeader {
  album: Album;
}

const AlbumHeader: React.FC<AlbumHeader> = ({ album }) => {
  const songsNumber = album.songs.length;

  return (
    <IonCard style={{ height: 170 }}>
      <IonRow class="ion-nowrap">
        <div style={{ position: "relative" }}>
          <IonImg
            src={album.art.src}
            style={{
              height: 170,
              width: 170,
              objectFit: "cover",
            }}
          />
          <IonText
            color="dark"
            class="ion-float-right"
            style={{
              position: "absolute",
              borderRadius: 8,
              bottom: 10,
              right: 10,
              zIndex: "10",
              padding: 5,
              boxShadow:
                "rgba(255, 255, 255, 0.75) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
            }}
          >
            <strong>
              {songsNumber} song{songsNumber === 1 ? "" : "s"}
            </strong>
          </IonText>
        </div>

        <IonCardContent
          style={{
            maxHeight: 170,
            width: "100%",
            padding: "10px 5px 10px 15px",
          }}
        >
          <IonCardTitle
            class="--color --ion-color-dark"
            style={{
              fontSize: 20,
              maxHeight: 48,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {album.title}
          </IonCardTitle>
          <IonCardSubtitle
            class="ion-text-lowercase"
            style={{
              overflow: "scroll",
              marginTop: 10,
              maxHeight: 90,
              textOverflow: "ellipsis",
            }}
          >
            {album.description}
          </IonCardSubtitle>
        </IonCardContent>
      </IonRow>
    </IonCard>
  );
};

export default AlbumHeader;
