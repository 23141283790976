import firebase from 'firebase/app'
import { firestore } from 'firebase/app'

import { GroupId } from '../models/Group'
import { FreshSong, Song, SongId, SongRef } from '../models/Song'
import { SpecifiedDate, Timestamp } from '../models/Timeline'
import { findHashtags, trimToTen } from '../util/sugar'

import { CommentThreadService } from './CommentThreadService'
import { DirectoryService } from './DirectoryService'
import ObserverService from './ObserverService'
import { SearchService } from './SearchService'
import isEmpty from 'lodash.isempty'

export class SongService {
  // static fetchRevisions(songId: string) {
  //   return new Promise<Song[]>((resolve, reject) => {
  //     const db = firestore();
  //     db.collection('songs').where("original", '==', songId).orderBy("updatedAt").get().then(songDocs => {
  //       let songs = songDocs.docs.map(x => SongService.StronglyTypeSongData(x))
  //       SongService.fetch(songId).then((song) => {
  //         if (!songs.map(x => x.id).includes(song.id))
  //           songs.push(song);
  //         resolve(songs)
  //       }).catch(reject);
  //     }).catch(reject);
  //   });
  // }

  static observe(
    groups: GroupId[],
    onFreshSong: (song: Song) => void,
    onRevokeSong: (songId: string) => void,
  ) {
    if (groups.length === 0) {
      return
    }
    if (ObserverService.please().exist('latest-song')) {
      console.error('observing already')
      return
    }
    const db = firestore()

    const revokeWatcher = db
      .collection('songs')
      .where('groups', 'array-contains-any', trimToTen(groups))
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .onSnapshot((songQuery) => {
        songQuery.docChanges().forEach(function (change) {
          if (change.type === 'removed') {
            // Lets make sure the song is actually not there...
            SongService.fetch(change.doc.id).catch(() => {
              onRevokeSong(change.doc.id)
            })
          }
        })
      })

    const watcher = db
      .collection('songs')
      .where('groups', 'array-contains-any', trimToTen(groups))
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .onSnapshot((songQuery) => {
        songQuery.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            const freshSong = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Song
            onFreshSong(freshSong)
          }
          if (change.type === 'modified') {
            const freshSong = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Song
            onFreshSong(freshSong)
          }
        })

        for (const songDoc of songQuery.docs) {
          const song = SongService.StronglyTypeSongData(songDoc)
          onFreshSong(song)
        }
      })

    ObserverService.please().add('songs', watcher)
    ObserverService.please().add('songs-revoked', revokeWatcher)
  }

  // static async revise(original: string, song: FreshSong) {
  //   const { description, authors, ownerId, title, media, artists, groups } = song;
  //   return new Promise<Song>((resolve, reject) => {
  //     const db = firestore();
  //     db.collection('songs').doc(original).update({ original }).then(() => {
  //       SongService.transmit({
  //         description,
  //         media,
  //         authors,
  //         artists,
  //         ownerId,
  //         title,
  //         original,
  //         groups,
  //         updatedAt: firestore.FieldValue.serverTimestamp()
  //       }).then((song) => {
  //           resolve(song);
  //         }
  //       ).catch(reject);
  //     }).catch(reject);
  //   });
  // }

  static toRef(song: Song): SongRef {
    return {
      id: song.id,
      updatedAt: song.updatedAt,
      comments: song.comments || [],
    } as SongRef
  }

  static transmit = async (freshSong: FreshSong): Promise<Song> => {
    const db = firestore()
    const songsDB = db.collection('songs')
    const artists = await Promise.all(
      freshSong.authors.map(async (artistId) =>
        DirectoryService.fetch(artistId),
      ),
    )

    freshSong.artists = {}

    for (const artist of artists) {
      let { id, ownerId, alias, avatar } = artist
      if (avatar === undefined) avatar = ''
      freshSong.artists[id] = { id, ownerId, alias, avatar }
      freshSong.privacy = artist.privacy || freshSong.privacy || 'public'
    }

    const tags = findHashtags(freshSong.description)

    if (tags) freshSong.tags = tags
    if (freshSong.title === ' ') freshSong.title = 'Untitled'

    const songDocs = await songsDB.get()
    const freshSongIndex = songDocs.docs.length + 1
    if (!freshSong.submission) freshSong.submission = freshSongIndex

    const { id } = await CommentThreadService.initialize()

    return new Promise((resolve, reject) => {
      const songRef = songsDB.doc(id)
      songRef.set(freshSong).then(() => {
        songRef
          .get()
          .then((songDoc) => {
            if (songDoc !== undefined) {
              const song = SongService.StronglyTypeSongData(songDoc)
              SearchService.please().pushSongToIndex(song)
              resolve(song)
            }
          })
          .catch(reject)
      })
    })
  }

  static updateSong = async (
    id: SongId,
    updatedSong: FreshSong,
    updateArtists: boolean = true,
  ) => {
    const freshSong = { ...updatedSong }

    !freshSong.art && delete freshSong.art

    if (updateArtists) {
      freshSong.artists = {}

      const artists = await Promise.all(
        freshSong.authors.map(async (artistId) =>
          DirectoryService.fetch(artistId),
        ),
      )

      for (const artist of artists) {
        const { id, ownerId, alias, avatar } = artist

        freshSong.artists[id] = { id, ownerId, alias, avatar: avatar || '' }
        freshSong.privacy = artist.privacy || updatedSong.privacy || 'public'
      }
    }

    return new Promise<FreshSong>((resolve, reject) => {
      const db = firestore()
      if (id) {
        db.collection('songs')
          .doc(id)
          .set({ ...freshSong }, { merge: true })
          .then(() => {
            resolve(freshSong)
            return freshSong
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        reject()
      }
    })
  }

  static delete = (id: string): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      const db = firestore()
      if (!id || id === '') {
        return
      }
      const { original } = await SongService.fetch(id)
      await db
        .collection('songs')
        .doc(id)
        .delete()
        .then(async () => {
          SearchService.please().removeSongFromIndex(id)
          resolve()
        })
      if (original && typeof original && original !== id) {
        db.collection('songs')
          .where('original', '==', original)
          .get()
          .then((queryResult) => {
            if (queryResult.size === 1) {
              db.collection('songs')
                .doc(original)
                .update({ original: firestore.FieldValue.delete() })
            }
          })
      }
    })
  }

  static getFileExtension = (url: string) => {
    return new Promise<string>(async (resolve, reject) => {
      const songRef = firebase.storage().refFromURL(url)

      const ext = await songRef
        .getMetadata()
        .then()
        .then((data) => data.name.split('.').slice(-1)[0])
        .catch((err) => {
          console.error(err)
          reject(err)
        })

      resolve(ext)
      return ext
    })
  }

  static fetch = (id: string) => {
    return new Promise<Song>((resolve, reject) => {
      const db = firestore()
      if (!id || id === '') {
        reject()
      } else {
        db.collection('songs')
          .doc(id)
          .get()
          .then((songDoc) => {
            if (!songDoc.exists) {
              resolve({} as Song)
            }
            resolve(SongService.StronglyTypeSongData(songDoc))
          })
          .catch(reject)
      }
    })
  }

  static fetchSome = (limit: number, groupIds: string[]): Promise<Song[]> => {
    return new Promise((resolve, reject) => {
      const db = firestore()
      db.collection('songs')
        .where('groups', 'array-contains-any', groupIds.reverse().slice(0, 10))
        .orderBy('updatedAt')
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static fetchSongsOnDate = (groups: string[], date: string) => {
    return new Promise<Song[]>((resolve, reject) => {
      const db = firestore()
      const dayBegin = new Date(date + 'T00:00:00')
      const dayEnd = new Date(date + 'T23:59:59')

      db.collection('songs')
        .where('groups', 'array-contains-any', trimToTen(groups))
        .orderBy('updatedAt')
        .where('updatedAt', '>', dayBegin)
        .where('updatedAt', '<', dayEnd)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static fetchMore = (
    groups: string[],
    limit: number,
    timestamp: Timestamp,
  ): Promise<Song[]> => {
    if (timestamp.seconds === -1) {
      return SongService.fetchSome(limit, groups)
    }
    return new Promise((resolve, reject) => {
      const db = firestore()
      db.collection('songs')
        .where('groups', 'array-contains-any', trimToTen(groups))
        .orderBy('updatedAt')
        .endBefore(timestamp)
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) =>
            SongService.StronglyTypeSongData(songDoc),
          )
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static fetchSongsForAssignment = (assignmentId: string): Promise<Song[]> => {
    return new Promise((resolve, reject) => {
      const db = firestore()
      db.collection('songs').orderBy("updatedAt","desc")
        .where('assignment', '==', assignmentId)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) =>
            SongService.StronglyTypeSongData(songDoc),
          )
          resolve(songBatch)
        })
        .catch((e)=>{
console.log(e)
reject(e);
        })
    })
  }

  /**
   * Fetch some songs with a particular hashtag
   *
   * @param tag the hashtag to query
   * @param limit how many songs to get
   */
  static fetchSongsWithHashtag = (tag: string, groups: string[]) => {
    return new Promise<Song[]>((resolve, reject) => {
      const db = firestore()
      db.collection('songs')
        .where('tags', 'array-contains', tag)
        .orderBy('updatedAt', 'desc')
        .limit(10)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            resolve([])
          }
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  /**
   * Fetch some songs with a particular hashtag
   *
   * @param tag the hashtag to query
   */
  static fetchSongsWithHashtagInComments = (tag: string) => {
    return new Promise<SongRef[]>((resolve, reject) => {
      const db = firebase.firestore()
      db.collection('threads')
        .where('tags', 'array-contains', tag)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            resolve([])
          }
          const songBatch = querySnapshot.docs.map((x) => {
            const updatedAt: Timestamp = { seconds: 0, nanoseconds: 0 }
            return { id: x.id, updatedAt }
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static fetchSongsByParams = (
    groups: string[],
    assignments: string[],
    dateOrder: string,
    isShowLowComments: boolean,
    specifiedDate: SpecifiedDate,
    isRandomSongs: boolean,
    randomId: string,
    fetchMore: boolean,
    lastVisibleSong: SongId,
  ) => {
    return new Promise<Song[]>(async (resolve, reject) => {
      const db = firestore()
      const songsDoc = db.collection('songs')

      // Get all sort fields and turn them into fields for firebase
      const isSpecifiedDate = dateOrder === 'specified'
      const sortedByDate = dateOrder === 'new' ? 'desc' : 'asc'

      // Define the documents by which we will search
      const currentDocument = isEmpty(assignments)
        ? songsDoc.where('groups', 'array-contains-any', groups)
        : songsDoc.where('assignment', 'in', assignments)

      // We divide the document in half depending on the song random id.
      // Then we determine (depending on the last received song) from which part of the part we will receive songs
      const firstRandomSongsPart = currentDocument.where(
        firestore.FieldPath.documentId(),
        '>=',
        randomId,
      )
      const secondRandomSongPart = currentDocument.where(
        firestore.FieldPath.documentId(),
        '<',
        randomId,
      )
      const isSongInFirstPartOfRandom =
        isEmpty(lastVisibleSong) ||
        (await firstRandomSongsPart
          .where(firestore.FieldPath.documentId(), '==', lastVisibleSong)
          .get()
          .then((data) => {
            return !data.empty
          }))

      // Choose which method to sort this data
      const specifiedByDate = currentDocument
        .where(
          'updatedAt',
          '<=',
          new Date(specifiedDate.year, specifiedDate.month + 1, 0),
        )
        .where(
          'updatedAt',
          '>=',
          new Date(specifiedDate.year, specifiedDate.month),
        )

      const orderedSongs = isShowLowComments
        ? currentDocument.where('commentsNumber', '<', 3)
        : currentDocument.orderBy('updatedAt', sortedByDate)
      const randomSongs = isSongInFirstPartOfRandom
        ? firstRandomSongsPart
        : secondRandomSongPart
      const currentSongsBySortType = isRandomSongs
        ? randomSongs
        : isSpecifiedDate
        ? specifiedByDate
        : orderedSongs

      // Depending on the type of action, we download the first 10 songs,
      // or the next 10 based on the last downloaded song in the collection
      const lastVisibleItem =
        !isEmpty(lastVisibleSong) && (await songsDoc.doc(lastVisibleSong).get())

      const fetch = () => currentSongsBySortType.limit(10)
      const fetchMoreSongs = () =>
        currentSongsBySortType.startAfter(lastVisibleItem).limit(10)
      const typeOfFetch = fetchMore ? fetchMoreSongs() : fetch()

      typeOfFetch
        .get()
        .then(async (querySnapshot) => {
          const songsData: Song[] = []

          await querySnapshot.docs.forEach((songDoc) =>
            songsData.push(SongService.StronglyTypeSongData(songDoc)),
          )

          // It is necessary in order to switch the receipt of random songs to the second part,
          // if we received all of them from the first part
          if (isRandomSongs && (songsData.length < 10 || querySnapshot.empty)) {
            const getSecondPartRandomSongs = () =>
              secondRandomSongPart
                .limit(10 - songsData.length)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.docs.forEach((songDoc) => {
                    songsData.push(SongService.StronglyTypeSongData(songDoc))
                  })
                })

            isSongInFirstPartOfRandom && (await getSecondPartRandomSongs())
          }

          resolve(songsData)
          return songsData
        })
        .catch((err) => {
          console.error(err, 'filtered songs are empty')
          reject()
        })
    })
  }

  static StronglyTypeSongData = (
    songDoc:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | firestore.DocumentSnapshot<firestore.DocumentData>,
  ) => {
    return { ...songDoc.data(), id: songDoc.id } as Song
  }
}
