import { firestore } from "firebase/app";
import { ArtistId, ArtistMeta } from "../models/Artist";
import { ArtistRef } from "../models/Directory";

export class DirectoryService {


    static updateArtist(artist: ArtistMeta) {
        var db = firestore();
        db.collection("artists").doc(artist.id).set(artist);
    }
    static fetch(artistId: ArtistId): Promise<ArtistRef> {
        return new Promise((resolve, reject) => {
            var db = firestore();
            const directoryEntryRef = db.collection("artists").doc(artistId);
            directoryEntryRef.get().then((directoryEntryDoc) => {
                const entry = DirectoryService.StronglyTypeArtistRefData(directoryEntryDoc)
                resolve(entry);
            }).catch(reject)
        });
    }

    static fetchMany(artistId: ArtistId[]): Promise<ArtistRef[]> {
        return new Promise((resolve, reject) => {
            var db = firestore();
            const directoryEntriesRef = db.collection("artists").where(firestore.FieldPath.documentId(), "in", artistId);
            directoryEntriesRef.get().then((directoryEntriesCollection) => {
                let matches: ArtistRef[] = [];
                for (const directoryEntryDoc of directoryEntriesCollection.docs) {
                    const entry = DirectoryService.StronglyTypeArtistRefData(directoryEntryDoc)
                    matches.push(entry);
                }
                resolve(matches);
            }).catch(reject)
        });
    }
    static fetchSome(pageSize: number, cursor: number, field: string): Promise<ArtistRef[]> {
        return new Promise((resolve, reject) => {
            var db = firestore();
            const directoryEntriesRef = db.collection("artists").orderBy("songs", "desc").startAfter(cursor).limit(pageSize);
            directoryEntriesRef.get().then((directoryEntriesCollection) => {
                let matches: ArtistRef[] = [];
                for (const directoryEntryDoc of directoryEntriesCollection.docs) {
                    const entry = DirectoryService.StronglyTypeArtistRefData(directoryEntryDoc)
                    matches.push(entry);
                }
                resolve(matches);
            }).catch(reject)
        });
    }
    static fetchAll(): Promise<ArtistRef[]> {
        return new Promise((resolve, reject) => {
            var db = firestore();
            const directoryEntriesRef = db.collection("artists");
            directoryEntriesRef.get().then((directoryEntriesCollection) => {
                let matches: ArtistRef[] = [];
                for (const directoryEntryDoc of directoryEntriesCollection.docs) {
                    const entry = DirectoryService.StronglyTypeArtistRefData(directoryEntryDoc)
                    matches.push(entry);
                }
                resolve(matches);
            }).catch(reject)
        });
    }


    static observe(onArtistsChange: (artists: ArtistRef[]) => void): void {
        var db = firestore();
        const directoryEntriesRef = db.collection("artists").orderBy("songs", "desc").where("songs", ">", 0);
        directoryEntriesRef.onSnapshot((directoryEntriesCollection) => {
            let matches: ArtistRef[] = [];
            for (const directoryEntryDoc of directoryEntriesCollection.docs) {
                const entry = DirectoryService.StronglyTypeArtistRefData(directoryEntryDoc)
                matches.push(entry);
            }
            onArtistsChange(matches);
        });
    }





    static StronglyTypeArtistRefData = (artistDoc: firestore.QueryDocumentSnapshot<firestore.DocumentData> | firestore.DocumentSnapshot<firestore.DocumentData>) => {
        const artist_data = { ...artistDoc.data(), id: artistDoc.id } as ArtistRef;
        return artist_data;
    }

}