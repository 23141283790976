import React, { useLayoutEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItemDivider,
  IonList,
  IonItem,
  IonLabel,
  IonCardSubtitle,
  IonCol,
} from "@ionic/react";

import { useRoles } from "../data/store/useRoles";
import { useArtists } from "../data/store/useArtists";

import findKey from "lodash.findkey";
import isEmpty from "lodash.isempty";
import { Role, RoleType } from "../models/Role";

import AsyncArtistButton from "./AsyncArtistButton";
import ChangeUserRole from "./ChangeUserRole";

export const RolesPanel: React.FC = () => {
  const { getUsersWithRoles, allUsersWithRoles, role } = useRoles();
  const { fetchAll, artists } = useArtists();

  const getUsers = (roles: RoleType) =>
    allUsersWithRoles.filter((item: Role) => item.authority.includes(roles));
  const allSuperAdmins = getUsers("super admin");
  const allAdmins = getUsers("admin");
  const allModerators = getUsers("moderator");

  const authority = role && role.authority;
  const isSuperAdmin = authority && authority.includes("super admin");
  const isAdmin = authority && authority.includes("admin");

  useLayoutEffect(() => {
    fetchAll();
    getUsersWithRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonCard>
      <IonCardHeader color="primary">
        <IonCardTitle color="secondary">Permissions</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        {!isEmpty(allSuperAdmins) && (
          <IonList>
            <IonCardSubtitle style={{ margin: "10px 0" }}>
              Super Admins:
            </IonCardSubtitle>
            {allSuperAdmins.map((item: Role) => {
              const userId = findKey(artists, { ownerId: item.ownerId }) || "";

              return (
                <IonItem key={item.ownerId}>
                  <IonLabel>
                    <AsyncArtistButton variant="alias" id={userId} />
                  </IonLabel>
                  {isSuperAdmin && (
                    <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                      <ChangeUserRole id={userId} />
                    </IonCol>
                  )}
                </IonItem>
              );
            })}
            <IonItemDivider color={"clear"} />
          </IonList>
        )}

        {!isEmpty(allAdmins) && (
          <IonList>
            <IonCardSubtitle style={{ margin: "10px 0" }}>
              Admins:
            </IonCardSubtitle>
            {allAdmins.map((item: Role) => {
              const userId = findKey(artists, { ownerId: item.ownerId }) || "";

              return (
                <IonItem key={item.ownerId}>
                  <IonLabel>
                    <AsyncArtistButton variant="alias" id={userId} />
                  </IonLabel>
                  {isSuperAdmin && (
                    <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                      <ChangeUserRole id={userId} />
                    </IonCol>
                  )}
                </IonItem>
              );
            })}
            <IonItemDivider color={"clear"} />
          </IonList>
        )}

        {!isEmpty(allModerators) && (
          <IonList>
            <IonCardSubtitle style={{ margin: "10px 0" }}>
              Moderators:
            </IonCardSubtitle>
            {allModerators.map((item: Role) => {
              const userId = findKey(artists, { ownerId: item.ownerId }) || "";

              return (
                <IonItem key={item.ownerId}>
                  <IonLabel>
                    <AsyncArtistButton variant="alias" id={userId} />
                  </IonLabel>

                  {(isSuperAdmin || isAdmin) && (
                    <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                      <ChangeUserRole id={userId} />
                    </IonCol>
                  )}
                </IonItem>
              );
            })}
            <IonItemDivider color="clear" />
          </IonList>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default RolesPanel;
