import create from "zustand";
import { ArtistId } from "../../models/Artist";
import {
  SongId,
  SongRef,
  FollowRef,
  FavoriteAlbumsRef,
} from "../../models/Song";
import { OwnerId } from "../../models/User";
import { FavoriteService } from "../../services/FavoriteService";
import { toTimestamp, uniq } from "../../util/sugar";
import { CommentThreadService } from "../../services/CommentThreadService";
import { CommentsDoc } from "../../models/Comment";
import { AlbumId } from "../../models/Albums";

export type FavoriteState = {
  favorites: SongRef[];
  followList: FollowRef[];
  favoriteAlbums: FavoriteAlbumsRef[];
  addFavorite: (ownerId: OwnerId, songId: SongId) => void;
  removeFavorite: (ownerId: OwnerId, songId: SongId) => void;
  isFavorite: (ownerId: OwnerId, songId: SongId) => boolean;
  addFavoriteAlbum: (ownerId: OwnerId, songId: AlbumId) => void;
  removeFavoriteAlbum: (ownerId: OwnerId, songId: AlbumId) => void;
  isFavoriteAlbum: (ownerId: OwnerId, songId: AlbumId) => boolean;
  follow: (ownerId: OwnerId, artistId: ArtistId) => void;
  unfollow: (ownerId: OwnerId, artistId: ArtistId) => void;
  isFollowing: (artistId: ArtistId) => boolean;
  initializeFavorites: (ownerId: OwnerId) => void;
  isLoading: boolean;
};

export const useFavorites = create<FavoriteState>((set, store) => ({
  favorites: [],
  followList: [],
  favoriteAlbums: [],
  isLoading: false,
  initializeFavorites: async (ownerId) => {
    set({ isLoading: true });
    const { follow } = await FavoriteService.fetchArtistFollowList(ownerId);
    if (typeof follow !== "undefined") {
      set({ followList: follow });
    }

    const { favoriteAlbums } =
      await FavoriteService.fetchArtistFavoriteAlbumsList(ownerId);
    if (typeof favoriteAlbums !== "undefined") {
      set({ favoriteAlbums });
    }

    const { favorites } = await FavoriteService.fetchArtistFavoriteList(
      ownerId
    );
    if (typeof favorites !== "undefined") {
      const enrichedFavorites: any = [];
      for (const favorite of favorites) {
        await CommentThreadService.fetch(favorite.id).then((threadDoc) => {
          const comments = (threadDoc as CommentsDoc).comments || [];
          enrichedFavorites.push({ ...favorite, comments });
        });
      }
      set({ favorites: enrichedFavorites, isLoading: false });
    }
  },
  isFavorite: (ownerId, songId) => {
    const favorites = store().favorites;
    return favorites.map((x) => x.id).includes(songId);
  },
  addFavorite: (ownerId, songId) => {
    const favorite: SongRef = {
      id: songId,
      updatedAt: toTimestamp(new Date().getSeconds()),
    };
    const favoritesList = store().favorites.map(({ id, updatedAt }) => ({
      id,
      updatedAt,
    }));
    const favorites = uniq([...favoritesList!, favorite]) as SongRef[];
    FavoriteService.changeFavorites({ id: ownerId, favorites });
    set({ favorites });
  },
  removeFavorite: (ownerId, songId) => {
    const favorites = store().favorites.filter(
      (songRef) => songRef.id !== songId
    );
    FavoriteService.changeFavorites({ id: ownerId, favorites });
    set({ favorites });
  },
  isFavoriteAlbum: (ownerId, albumId) => {
    const favorites = store().favoriteAlbums;
    return favorites.map((x) => x.id).includes(albumId);
  },
  addFavoriteAlbum: (ownerId, albumId) => {
    const favoriteAlbum: SongRef = {
      id: albumId,
      updatedAt: toTimestamp(new Date().getSeconds()),
    };
    const favoriteAlbumsList = store().favoriteAlbums.map(
      ({ id, updatedAt }) => ({
        id,
        updatedAt,
      })
    );
    const favoriteAlbums = uniq([
      ...favoriteAlbumsList!,
      favoriteAlbum,
    ]) as FavoriteAlbumsRef[];
    FavoriteService.changeFavoriteAlbums({ id: ownerId, favoriteAlbums });
    set({ favoriteAlbums });
  },
  removeFavoriteAlbum: (ownerId, albumId) => {
    const favoriteAlbums = store().favoriteAlbums.filter(
      (albumRef) => albumRef.id !== albumId
    );
    FavoriteService.changeFavoriteAlbums({ id: ownerId, favoriteAlbums });
    set({ favoriteAlbums });
  },
  isFollowing: (artistId) => {
    const followList = store().followList;
    return followList.map((x) => x.id).includes(artistId);
  },
  follow: (ownerId, artistId) => {
    const follow: SongRef = {
      id: artistId,
      updatedAt: toTimestamp(new Date().getSeconds()),
    };
    const followList = uniq([...store().followList!, follow]) as FollowRef[];
    FavoriteService.changeFollow({ id: ownerId, follow: followList });
    set({ followList });
  },
  unfollow: (ownerId, artistId) => {
    const followList = store().followList.filter(
      (followRef) => followRef.id !== artistId
    );
    FavoriteService.changeFollow({ id: ownerId, follow: followList });
    set({ followList });
  },
}));
