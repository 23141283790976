import firebase from 'firebase/app';
import 'firebase/auth';
import { ArtistId } from '../models/Artist';
import { ArtistService } from './ArtistService';
import ObserverService from './ObserverService';
import { ProfileService } from './ProfileService';

(window as any).authChangeWatcher = Function;


const firebaseConfig = {
  // DEV
  // apiKey: "AIzaSyDTmm_e8foz5G8TwXqivlqhg5e0KNiqv0g",
  // authDomain: "songaday-net-dev.firebaseapp.com",
  // projectId: "songaday-net-dev",
  // storageBucket: "songaday-net-dev.appspot.com",
  // messagingSenderId: "896039456820",
  // appId: "1:896039456820:web:b34cc2528839367df5fa61"

  // PROD
  apiKey: 'AIzaSyDcxWmSLrMcuflhGv71Yn_LinkmDupuKdI',
  authDomain: 'songaday-net.firebaseapp.com',
  projectId: 'songaday-net',
  storageBucket: 'songaday-net.appspot.com',
  messagingSenderId: '1030831187630',
  appId: '1:1030831187630:web:31e82b296a91035bb96f3f',
  measurementId: 'G-9790JBJDFE'
};


firebase.initializeApp(firebaseConfig);
// firebase.firestore().enablePersistence()
//     .catch(function (err) {
//         console.log(err);
//     });


export class LoginService {
  static login = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    firebase.auth().signInWithPopup(provider);
  }
  static logout = () => {
    return firebase.auth().signOut()
  }
  static watchAuthState = (onLoggedIn: (artistId: ArtistId, ownerId: string) => void, onLoggedOut: () => void) => {
    const isObserving = ObserverService.please().exist('authentication');
    if (isObserving) {
      console.log('authstate observing already')
      return;
    } else {
      const authObserver = firebase.auth().onAuthStateChanged(function (user) {
        if (user && user.providerData[0] && user.providerData[0].email === null && user.email !== null) {
          user.providerData[0].email = user.email;
        }
        const providerData = user && { ...user, ...user.providerData[0] };
        if (user && providerData && 'email' in providerData) {
          // User is signed in.
          const artistId = ArtistService.calculateArtistId((providerData as any)['email'])

          const promptForNewAvatarAndLogin = () => {
            const alias = window.prompt('Alias not found, what do we call you?');
            if (alias) {
              let change = { ownerId: user.uid } as any;
              if (alias)
                change['alias'] = alias;
              ProfileService.changeProfile(change, artistId).then(() => {
                onLoggedIn(artistId, user.uid);

              });
            } else {
              onLoggedIn(artistId, user.uid);
            }
          }

          ArtistService.fetch(artistId).catch(promptForNewAvatarAndLogin).then((artist) => {
            if (artist && typeof artist.alias === undefined) {
              promptForNewAvatarAndLogin();
            } else {
              // console.log("Successfully logged in as " + (artist as any || { alias: "" }).alias);
              onLoggedIn(artistId, user.uid);
            }
          })
        } else {
          onLoggedOut();
        }
      });
      ObserverService.please().add('authentication', authObserver);
    }
  }
}
