import produce from "immer";
import create from "zustand";
import { PlaylistId, Playlist } from "../../models/Playlists";
import { MediaReference, SongId } from "../../models/Song";
import { PlaylistService } from "../../services/PlaylistService";
import { toTimestamp, uuidv4 } from "../../util/sugar";
import { songHookStatus } from "./useSongs";

export type PlaylistsState = {
  status: songHookStatus;
  playlistId: PlaylistId | undefined;
  all: Playlist[];
  playlists: Record<PlaylistId, Playlist>;
  retrieve: (id: PlaylistId) => Playlist | undefined;
  fetchAll: () => Promise<Playlist[]>;
  fetch: (id: PlaylistId) => void;
  updatePlaylist: (freshPlaylist: Playlist) => void;
  transmit: (
    owner: string,
    name: string,
    art: MediaReference,
    isPrivate: boolean,
    songs: string[],
    authorId: string
  ) => Promise<PlaylistId>;
  delete: (id: PlaylistId) => void;
  isInPlaylist: (id: PlaylistId, songId: SongId) => boolean;
  addToPlaylist: (id: PlaylistId, songId: SongId) => Promise<Playlist>;
  removeFromPlaylist: (id: PlaylistId, songId: SongId) => Promise<Playlist>;
};

export const usePlaylists = create<PlaylistsState>((set, get) => ({
  status: "idle",
  playlistId: undefined,
  all: [],
  playlists: {},
  fetchAll: async () => {
    set({ status: "fetching" });
    return new Promise(async (resolve) => {
      let playlistsData = await PlaylistService.fetchAll();

      let datedLists = [];
      let undatedLists = [];

      for (const playlist of playlistsData) {
        playlist.updatedAt
          ? datedLists.push(playlist)
          : undatedLists.push(playlist);
      }

      datedLists.sort((a, b) => b.updatedAt - a.updatedAt);
      undatedLists.sort((a, b) => a.title.localeCompare(b.title));

      const allPlaylists = [...datedLists, ...undatedLists];

      const newState = produce(get(), (draft) => {
        for (const playlist of allPlaylists) {
          draft.playlists[playlist.id] = playlist;
        }
        draft.all = allPlaylists;
        draft.status = "idle";
      });
      set(newState);
      resolve();
    });
  },
  fetch: async (id) => {
    set({ status: "fetching" });
    const playlist = await PlaylistService.fetch(id);
    const newState = produce(get(), (draft) => {
      draft.playlists[playlist.id] = playlist;
      draft.status = "idle";
    });
    set(newState);
  },
  retrieve: (id) => {
    return get().playlists[id];
  },
  transmit: (ownerId, title, art, isPrivate, songs, authorId) => {
    set({ status: "fetching" });

    const songsList = songs.map((id, i) => ({
      id,
      updatedAt: { nanoseconds: 0, seconds: i },
    }));
    const playlist: Playlist = {
      authorId,
      id: uuidv4(),
      ownerId,
      songs: songsList,
      title,
      art,
      isPrivate,
      updatedAt: Date.now(),
    };
    return new Promise((resolve, reject) => {
      PlaylistService.transmit(playlist)
        .then(() => {
          set({ playlistId: playlist.id });
          resolve(playlist.id);
        })
        .catch(reject);

      set({ status: "idle" });
    });
  },
  delete: (id) => {
    set({ status: "deleting" });

    return new Promise((resolve, reject) => {
      PlaylistService.delete(id)
        .then(() => {
          const newState = produce(get(), (draft) => {
            const filteredLists = get().all.filter(
              (playlist) => playlist.id !== id
            );
            for (const playlist of filteredLists) {
              draft.playlists[playlist.id] = playlist;
            }
            draft.all = filteredLists;
            delete draft.playlists[id];
            draft.status = "idle";
          });
          set(newState);
        })
        .then(resolve)
        .catch(reject);
    });
  },
  updatePlaylist: (freshPlaylist: Playlist) => {
    set({ status: "fetching" });

    return new Promise((resolve, reject) => {
      PlaylistService.update(freshPlaylist)
        .then(() => {
          const nextState = produce(get(), (draftState) => {
            draftState.playlists[freshPlaylist.id] = {
              ...freshPlaylist,
            };
          });

          set(nextState);
          resolve();
        })
        .catch(reject)
        .finally(() => set({ status: "idle" }));
    });
  },
  isInPlaylist: (id, songId) => {
    return get()
      .playlists[id].songs.map((x) => x.id)
      .includes(songId);
  },
  addToPlaylist: (id, songId) => {
    const playlist = get().playlists[id];
    const newSong = {
      id: songId,
      updatedAt: toTimestamp(new Date().getSeconds()),
    };
    const playlistUpdate = { ...playlist, songs: [...playlist.songs, newSong] };
    return new Promise((resolve, reject) => {
      PlaylistService.update(playlistUpdate)
        .then((newPlaylist) => {
          const newState = produce(get(), (draftState) => {
            draftState.playlists[id] = newPlaylist;
            draftState.status = "idle";
          });
          set(newState);
          resolve();
        })
        .catch(reject);
    });
  },
  removeFromPlaylist: (id, songId) => {
    const playlist = get().playlists[id];
    const playlistUpdate = {
      ...playlist,
      songs: [...playlist.songs.filter((item) => item.id !== songId)],
    };

    return new Promise((resolve, reject) => {
      PlaylistService.update(playlistUpdate)
        .then((newPlaylist) => {
          const newState = produce(get(), (draftState) => {
            draftState.playlists[id] = newPlaylist;
            draftState.status = "idle";
          });
          set(newState);
          resolve();
        })
        .catch(reject);
    });
  },
}));
