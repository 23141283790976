import { firestore } from "firebase/app";
import { Album } from "../models/Albums";

export class AlbumsService {
  static StronglyTypeAlbumData = (
    albumDoc:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | firestore.DocumentSnapshot<firestore.DocumentData>
  ) => {
    return { ...albumDoc.data(), id: albumDoc.id } as Album;
  };

  static transmit = (freshAlbum: Album) => {
    const db = firestore();
    return db.collection("albums").doc(freshAlbum.id).set(freshAlbum);
  };

  static fetchAll = () => {
    return new Promise<Album[]>((resolve, reject) => {
      const db = firestore();
      db.collection("albums")
        .get()
        .then((albumsCollection) => {
          const allAlbums = albumsCollection.docs.map((albumDoc) => {
            return AlbumsService.StronglyTypeAlbumData(albumDoc);
          });
          resolve(allAlbums);
        })
        .catch(reject);
    });
  };

  static fetch = (id: string) => {
    return new Promise<Album>((resolve, reject) => {
      const db = firestore();
      db.collection("albums")
        .doc(id)
        .get()
        .then((albumDoc) => {
          const album = AlbumsService.StronglyTypeAlbumData(albumDoc);
          resolve(album);
        })
        .catch(reject);
    });
  };

  static delete = (id: string): Promise<void> => {
    return new Promise<void>((resolve) => {
      const db = firestore();
      if (!id || id === "") {
        return;
      }
      db.collection("albums")
        .doc(id)
        .delete()
        .then(() => {
          resolve();
        });
    });
  };

  static update = (album: Album): Promise<Album> => {
    return new Promise<Album>((resolve, reject) => {
      const db = firestore();
      const serverAlbum = db.collection("albums").doc(album.id);
      serverAlbum.set(album, { merge: true }).then(() => {
        serverAlbum
          .get()
          .then((albumDoc) => {
            const album = AlbumsService.StronglyTypeAlbumData(albumDoc);
            resolve(album);
          })
          .catch(reject);
      });
    });
  };
}
