import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  IonButton,
  IonImg,
  IonButtons,
  IonLoading,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonCard,
  IonToolbar,
  IonItem,
  IonLabel,
} from "@ionic/react";
import {
  starOutline,
  starSharp,
  eyeOffOutline,
  eyeOutline,
  closeCircleOutline,
  searchOutline,
} from "ionicons/icons";

import { useAlbums } from "../data/store/useAlbums";
import { useProfile } from "../data/store/useProfile";
import { useArtists } from "../data/store/useArtists";
import { useFavorites } from "../data/store/useFavorites";

import isEmpty from "lodash.isempty";
import { Album } from "../models/Albums";
import { usePageTitle } from "../util/sugar";
import {
  warningFavoritesAlbumText,
  warningGlobalAlbumsText,
  warningPersonalAlbumText,
} from "../util/constants";

import Content from "../components/Content";
import AsyncArtistButton from "../components/AsyncArtistButton";
import AnimatedSearchBar from "../components/AnimatedSearchBar";
import WarningCard from "../components/WarningCard";

import "../components/styles.scss";

interface AlbumsProps {}

const Albums: React.FC<AlbumsProps> = () => {
  const { authorId, ownerId } = useProfile();
  const {
    status: albumsStatus,
    all,
    albums,
    fetchAll,
    updateAlbum,
  } = useAlbums();
  const {
    isFavoriteAlbum,
    removeFavoriteAlbum,
    addFavoriteAlbum,
    favoriteAlbums,
  } = useFavorites();
  const { status } = useArtists((state) => state);

  const [albumsState, setAlbumsState] = useState("personalAlbums");
  const [currentAlbums, setCurrentAlbums] = useState<Album[]>([]);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [query, setQuery] = useState("");

  const isPersonalAlbums = albumsState === "personalAlbums";
  const isFavoriteAlbums = albumsState === "favoriteAlbums";
  const warningText = isPersonalAlbums
    ? warningPersonalAlbumText
    : isFavoriteAlbums
    ? warningFavoritesAlbumText
    : warningGlobalAlbumsText;

  usePageTitle("Albums");

  const toggleAlbumVisible = (album: Album) => async () => {
    const freshAlbum = { ...album, isPrivate: !album.isPrivate };
    await updateAlbum(freshAlbum);
  };

  const toggleAlbumsState = ({ detail: { value } }: any) => {
    setAlbumsState(value);
  };

  useEffect(() => {
    const allPersonalAlbums = all.filter((item) => item.ownerId === ownerId);
    const allGlobalAlbums = all.filter(
      (item) => item.ownerId !== ownerId && !item.isPrivate
    );
    const allFavoriteAlbums = allGlobalAlbums.filter((item) =>
      favoriteAlbums.some((favoriteAlbum) => item.id === favoriteAlbum.id)
    );

    switch (albumsState) {
      case "personalAlbums":
        setCurrentAlbums(allPersonalAlbums);
        break;
      case "globalAlbums":
        setCurrentAlbums(allGlobalAlbums);
        break;
      case "favoriteAlbums":
        setCurrentAlbums(allFavoriteAlbums);
        break;
      default:
        break;
    }
  }, [all, ownerId, albumsState, favoriteAlbums]);

  useLayoutEffect(() => {
    fetchAll().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content>
      <IonRow>
        <IonCol sizeMd="12" sizeLg="8" offsetLg="2">
          <IonRow
            style={{ display: "flex", flexWrap: "nowrap", zIndex: 10 }}
            class="ion-align-items-center"
          >
            <IonSegment value={albumsState} onIonChange={toggleAlbumsState}>
              <IonSegmentButton value="personalAlbums">
                Personal Albums
              </IonSegmentButton>
              <IonSegmentButton value="favoriteAlbums">
                Favorite Albums
              </IonSegmentButton>
              <IonSegmentButton value="globalAlbums">
                All Albums
              </IonSegmentButton>
            </IonSegment>
            <IonButton
              size="small"
              style={{ zIndex: 10 }}
              onClick={() => setIsSearchEnabled((prevState) => !prevState)}
            >
              <IonIcon
                icon={isSearchEnabled ? closeCircleOutline : searchOutline}
              />
            </IonButton>
          </IonRow>

          <AnimatedSearchBar isOpen={isSearchEnabled} onChange={setQuery} />

          <IonRow class="ion-justify-content-center">
            {!isEmpty(currentAlbums) ? (
              currentAlbums
                .filter((item) => item.title.toLowerCase().includes(query))
                .map((filteredItem: Album, index: number) => {
                  const album = albums[filteredItem.id];

                  return (
                    <IonCard key={album.id} className="albumItem">
                      <IonButtons
                        class="ion-justify-content-between  ion-align-items-center"
                        style={{
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        {album.art && album.art.src ? (
                          <IonImg
                            src={album.art.src}
                            slot="start"
                            style={{
                              height: "120px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <IonImg
                            src="/assets/icon/apple-touch-icon.png"
                            slot="start"
                            style={{ height: "120px", width: "100%" }}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            width: "100%",
                            padding: 5,
                          }}
                        >
                          <IonItem
                            button
                            lines="none"
                            routerLink={"/album/" + album.id}
                            detail={false}
                            class="ion-no-margin ion-no-padding ion-text-center"
                            style={{
                              paddingLeft: 8,
                            }}
                          >
                            <IonLabel class="ion-no-margin" color="dark">
                              {album.title}
                            </IonLabel>
                          </IonItem>

                          <IonToolbar>
                            <div slot="secondary">
                              <AsyncArtistButton
                                key={index}
                                id={album.authorId}
                                variant="album"
                              />
                            </div>

                            {album.authorId === authorId ? (
                              <IonButton
                                slot="primary"
                                onClick={toggleAlbumVisible(album)}
                              >
                                <IonIcon
                                  color={
                                    album.isPrivate ? undefined : "warning"
                                  }
                                  icon={
                                    album.isPrivate ? eyeOffOutline : eyeOutline
                                  }
                                />
                              </IonButton>
                            ) : (
                              <IonButton
                                color="dark"
                                slot="primary"
                                onClick={() => {
                                  !isFavoriteAlbum(ownerId!, album.id)
                                    ? addFavoriteAlbum(ownerId!, album.id)
                                    : removeFavoriteAlbum(ownerId!, album.id);
                                }}
                              >
                                <IonIcon
                                  slot="start"
                                  color={
                                    isFavoriteAlbum(ownerId, album.id)
                                      ? "warning"
                                      : undefined
                                  }
                                  icon={
                                    !isFavoriteAlbum(ownerId, album.id)
                                      ? starOutline
                                      : starSharp
                                  }
                                />
                              </IonButton>
                            )}
                          </IonToolbar>
                        </div>
                      </IonButtons>
                    </IonCard>
                  );
                })
            ) : (
              <WarningCard text={warningText} />
            )}
          </IonRow>

          <IonButton expand="full" color="primary" routerLink="/album-create">
            Create album
          </IonButton>

          <IonLoading
            isOpen={
              albumsStatus === "fetching" ||
              albumsStatus === "deleting" ||
              status === "fetching"
            }
          />
        </IonCol>
      </IonRow>
    </Content>
  );
};

export default React.memo(Albums);
