import React, { useCallback, useState } from "react";
import {
  IonButton,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonReorderGroup,
  IonReorder,
  IonAlert,
} from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";

import { useAssignments } from "../data/store/useAssignments";
import { useProfile } from "../data/store/useProfile";

import { GroupId } from "../models/Group";
import { Assignment } from "../models/Assignment";
import { uuidv4 } from "../util/sugar";

export const AssignmentAdmin: React.FC<{ groupId: GroupId }> = ({
  groupId,
}) => {
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const { ownerId } = useProfile();
  const { setAssignment, assignmentsInGroup, deleteAssignment } =
    useAssignments();
  const groupAssignments = assignmentsInGroup(groupId);

  const onSortAssignments = (event: CustomEvent<ItemReorderEventDetail>) => {
    let items = [...groupAssignments];
    items = event.detail.complete(items);

    const sortedItems = items.map((item, index) => ({ ...item, order: index }));

    for (const sortedAssignment of sortedItems) {
      setAssignment(sortedAssignment.ownerId, groupId, sortedAssignment);
    }
  };

  const updateAssignment = useCallback(
    (assigment, title, status) => {
      const freshAssignment: Assignment = { ...assigment, title, status };
      setAssignment(ownerId!, groupId, freshAssignment);
    },
    [groupId, ownerId, setAssignment]
  );

  const addNewAssignment = useCallback(() => {
    const id = uuidv4();
    const newAssignment: Assignment = {
      id,
      expires: { seconds: 2, nanoseconds: 3 },
      starts: { seconds: 23, nanoseconds: 2 },
      status: "hidden",
      title: "",
      order: groupAssignments.length + 1,
      ownerId: ownerId!,
    };
    setAssignment(ownerId!, groupId, newAssignment);
  }, [groupAssignments, groupId, ownerId, setAssignment]);

  return (
    <IonReorderGroup disabled={false} onIonItemReorder={onSortAssignments}>
      {groupAssignments &&
        groupAssignments.map((assigment) => (
          <IonItem key={assigment.id}>
            <IonReorder slot="start" />
            <IonInput
              onIonChange={(e) => {
                const title = (e.target as any).value;
                ownerId &&
                  groupId &&
                  title &&
                  updateAssignment(assigment, title, assigment.status);
              }}
              debounce={200}
              placeholder="Assignment name"
              value={assigment.title}
            />
            <IonSelect
              value={assigment.status}
              onIonChange={(e) => {
                const status = (e.target as any).value;
                if (status === "delete") {
                  setIsShowAlert(true);
                } else {
                  ownerId &&
                    groupId &&
                    status &&
                    updateAssignment(assigment, assigment.title, status);
                }
              }}
            >
              <IonSelectOption value={"hidden"}>Hidden</IonSelectOption>
              <IonSelectOption value={"active"}>Active</IonSelectOption>
              <IonSelectOption value={"expired"}>Expired</IonSelectOption>
              <IonSelectOption value={"delete"}>Delete</IonSelectOption>
            </IonSelect>

            <IonAlert
              isOpen={isShowAlert}
              onDidDismiss={() => setIsShowAlert(false)}
              header="Confirm"
              message={`Do you want to delete ${assigment.title} ?`}
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                  cssClass: "secondary",
                },
                {
                  text: "Delete",
                  handler: () => {
                    setIsShowAlert(false);
                    deleteAssignment(ownerId, groupId, assigment.id);
                  },
                },
              ]}
            />
          </IonItem>
        ))}

      <IonButton expand="full" onClick={addNewAssignment}>
        Add Assignment
      </IonButton>
    </IonReorderGroup>
  );
};
