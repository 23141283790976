import React, { memo } from 'react';
// import { Canvas } from 'react-three-fiber';
// import * as THREE from "three";
import Content from '../components/Content';
// import { usePlayer } from '../data/store/usePlayer';
// import { useProfile } from '../data/store/useProfile';
// import MediaService from '../services/MediaService';


const Visualizer: React.FC = () => {
    // const { darkMode } = useProfile();
    // const { isPaused } = usePlayer();

    // const analysis = MediaService.please().initializeAnalysis() ? "active" : "inactive";
    // const initialize = () => { MediaService.please().initializeAnalysis(); }
    return (
        <Content>
            {/* {isPaused && analysis === 'inactive' && <><IonCardHeader>Play a song before activating visualizer</IonCardHeader>
                <SongItem id="-MLFdSTdCeREjAz7I9Cw"></SongItem></>}
            {!isPaused && analysis === "inactive" && <IonCard><IonButton onClick={initialize} >Initialize Analyzer</IonButton></IonCard>}
            <Canvas
                gl={{ alpha: false, antialias: false, logarithmicDepthBuffer: true }}
                camera={{ fov: 75, position: [0, 0, 70] }}
                onCreated={({ gl }) => {
                    gl.setClearColor(darkMode ? 'black' : 'white')
                    gl.toneMapping = THREE.ACESFilmicToneMapping
                    gl.outputEncoding = THREE.sRGBEncoding
                }}>
                <ambientLight intensity={1} />
                {analysis === "active" && <Swarm />}
            </Canvas> */}
        </Content>
    );
};



export default memo(Visualizer);
