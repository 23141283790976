import React, { FC, useEffect, useRef } from 'react';
import { IonButton } from '@ionic/react';
import MediaService from '../services/MediaService';
import { usePlayer } from '../data/store/usePlayer';
import { usePlaylist } from '../data/store/usePlaylist';
import { useSongs } from '../data/store/useSongs';


interface PlayerProps {}


const Player: FC<PlayerProps> = () => {
  const { playNow, pauseNow, songIsOver } = usePlayer();
  const { getNextSongIfAvailable } = usePlaylist();
  const { retrieve } = useSongs();
  const playerRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (playerRef.current) {
      MediaService.please().setPlayerRef(playerRef);
      document.addEventListener('fullscreenchange', () => {
        // document.fullscreenElement will point to the element that
        // is in fullscreen mode if there is one. If there isn't one,
        // the value of the property is null.

        setTimeout(() => {
          MediaService.please().play();
        }, 500);
      });
    }
  }, [playerRef]);

  return (
    <IonButton>
      <video
        onEnded={() => {
          songIsOver();
          const nextSong = retrieve(getNextSongIfAvailable());
          nextSong && MediaService
            .please()
            .setSong(nextSong)
            .then(() => MediaService.please().play());
        }}
        onPlay={() => playNow()}
        onPause={() => pauseNow()}
        onClick={()=>{
          playerRef.current!.requestFullscreen()
        }}
        controlsList='nodownload'
        id='sadfam-media'
        crossOrigin='anonymous'
        style={{ height: '100%' }}
        ref={playerRef}
      />
    </IonButton>
  );
}

export default React.memo(Player);
