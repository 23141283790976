import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonSpinner,
} from "@ionic/react";
import { mailOutline } from "ionicons/icons";

import { useConfig } from "../data/store/useConfig";
import { useInviteCodes } from "../data/store/useInvites";
import { useProfile } from "../data/store/useProfile";
import { ArtistService } from "../services/ArtistService";

import { AssignmentAdmin } from "./AssignmentAdmin";
import BulkEmailInput from "./BulkEmailInput";
import GroupInviteChip from "./GroupInviteChip";
import Spacer from "./Spacer";
import AsyncArtistButton from "./AsyncArtistButton";
import ConfirmationAlert from "./ConfirmationAlert";
import { group } from "console";
import { Group } from "../models/Group";
import ArtistSelector from "./ArtistSelector";

interface groupFormProps {
  groupId: string;
  onComplete: () => void;
}

const GroupForm: React.FC<groupFormProps> = ({ groupId, onComplete }) => {
  const { changeGroup, deleteGroup, fetchGroup } = useConfig();
  const { authorId } = useProfile();
  const { setInviteCode, getInviteCode } = useInviteCodes();
  const ownerId = useProfile((x) => x.ownerId);
  const [group, setGroup] = useState<Group>({ authorId: "", description: "", id: "", invites: [], members: [], ownerId: "", path: "", title: "", active: false });


  const [isInvitingMember, setIsInvitingMember] = useState(false);
  const [isAddingMember, setAddingMember] = useState(false);
  const [inviteCode, setInviteCodeState] = useState<string | undefined>();
  const [showAlert, setShowAlert] = useState<string>("");
  const [listState, setListState] = useState<string>("add");
  useEffect(() => {
    fetchGroup(groupId).then((groupFetched) => {
      console.log(groupFetched)
      setGroup({ ...groupFetched, members: [...groupFetched.members || []].filter(Boolean) });
    })
  }, [ownerId, fetchGroup, groupId]);


  const toggleDisplay = (val: any) => {
    setListState(val.detail.value);
  };

  const handleDeleteGroup = () => {
    deleteGroup(groupId);
    onComplete();
  };

  const handleDeleteMember = (memberId: string) => () => {
    const members = group.members.filter((item) => item !== memberId);
    setGroup({ ...group, members })
  };

  const handleDeleteInvite = (invite: string) => () => {
    const invites = group.invites.filter((item) => item !== invite);
    setGroup({ ...group, invites });
  };

  useEffect(() => {
    if (typeof ownerId === "undefined" || ownerId === "") return;
    if (typeof inviteCode !== "undefined")
      groupId && group && setInviteCode(groupId, ownerId, inviteCode);
    else {
      ownerId && group && getInviteCode(groupId, ownerId).then((code) => {
        setInviteCodeState(code);
      }).catch(() => {
        console.log("no invite code");
      });
    }
  }, [
    changeGroup,
    groupId,
    ownerId,
    getInviteCode,
    inviteCode,
    setInviteCode,
  ]);


  if (!group) {
    return <>Loading...<IonSpinner /></>
  }
  return (
    <>
      <IonList className="inner-chill">
        <IonItem>
          <IonLabel color="tertiary" position="floating">
            Title
          </IonLabel>
          <IonInput
            value={group.title}
            onIonChange={(e) => {
              group.title !== e.detail.value && changeGroup(ownerId, groupId, "title", e.detail.value!);
              setGroup(({ ...group, title: e.detail.value! }))
            }
            }
            type="text"
          />
        </IonItem>

        <IonItem>
          <IonLabel color="tertiary" position="floating">
            Description
          </IonLabel>
          <IonInput
            value={group.description}
            onIonChange={(e) => {
              group.description !== e.detail.value && changeGroup(ownerId, groupId, "description", e.detail.value!)
              setGroup(({ ...group, description: e.detail.value! }))
            }}
            type="text"
          />
        </IonItem>

        <IonItem>
          <IonLabel color="tertiary" position="floating">
            Invite Code
          </IonLabel>
          <IonInput
            debounce={100}
            value={inviteCode}
            onIonChange={(e) => setInviteCodeState(e.detail.value!)}
            type="text"
          />
        </IonItem>

        {inviteCode && (
          <IonItem
            color="primary"
            target="_blank"
            href={"/invitation/?session-id=" + groupId + "&title=" + group.title}
          >
            Invitation Link
            <IonIcon icon={mailOutline} />
          </IonItem>
        )}

        <Spacer height={20} />
        {group && <IonItem>
          <IonLabel color="tertiary">Active</IonLabel>
          <IonButtons slot="end">
            <IonToggle
              onIonChange={(e) => {
                changeGroup(ownerId, groupId, "active", e.detail.checked)
                setGroup(({ ...group, active: e.detail.checked }))
              }}
              checked={group.active}
            />
          </IonButtons>
        </IonItem>}

        <Spacer height={30} />
        {group && <AssignmentAdmin groupId={group.id} />}

        <IonCard>
          <IonSegment value={listState} onIonChange={toggleDisplay}>
            <IonSegmentButton value="add">Add Members</IonSegmentButton>
            <IonSegmentButton value="remove">Remove Members</IonSegmentButton>
          </IonSegment>
        </IonCard>

        {listState === "add" ? (
          isInvitingMember ? (
            <BulkEmailInput
              onClose={() => setIsInvitingMember(false)}
              onEmailsAdded={(emails) => {
                const newInvites = [...new Set([...group.invites, ...emails])];
                changeGroup(ownerId, groupId, "invites", newInvites);
                setGroup({ ...group, invites: newInvites })
                setIsInvitingMember(false);
              }}
            />
          ) : (
            <>
              {isAddingMember && <>
                <ArtistSelector members={group.members} placeholder="add a member" setMembers={(members: string[]) => {
                  console.log(members);
                  changeGroup(ownerId, groupId, "members", members)
                  setGroup({ ...group, members })
                }}></ArtistSelector>
                <IonButton expand="full" onClick={() => {
                  setAddingMember(false);
                }}>
                  Done
                </IonButton>
              </>}
              {group.invites && (
                <IonRow style={{ display: "flex", alignItems: "center" }}>
                  <IonLabel>Invited Users:</IonLabel>
                  {group.invites.map((invite) => (
                    <div key={invite}>
                      <GroupInviteChip
                        showDeleteIcon
                        email={invite}
                        onClick={() => setShowAlert(`delete invite ${invite}`)}
                      />
                      <ConfirmationAlert
                        message={`Do you want to delete invite?`}
                        onConfirm={handleDeleteInvite(invite)}
                        onDismiss={() => setShowAlert("")}
                        isAlertOpen={showAlert === `delete invite ${invite}`}
                      />
                    </div>
                  ))}
                </IonRow>
              )}

              <IonItem>
                <IonButtons slot="end">
                  <IonButton
                    onClick={() => {
                      setIsInvitingMember(true);
                    }}
                  >
                    Invite Members
                  </IonButton>
                  <IonButton
                    onClick={() => {
                      setAddingMember(true);
                    }}
                  >
                    Add Member
                  </IonButton>

                </IonButtons>
              </IonItem>
            </>
          )
        ) : (
          <IonRow style={{ display: "flex", alignItems: "center" }}>
            <IonLabel>Group members:</IonLabel>
            {group.members.map((memberId) => {
              return (
                <div key={memberId}>
                  <AsyncArtistButton
                    onClick={() => setShowAlert(`delete member ${memberId}`)}
                    variant="chipCloseIcon"
                    id={memberId}
                  />
                  <ConfirmationAlert
                    message="Do you want to delete member ?"
                    onConfirm={handleDeleteMember(memberId)}
                    onDismiss={() => setShowAlert("")}
                    isAlertOpen={showAlert === `delete member ${memberId}`}
                  />
                </div>
              );
            })}
          </IonRow>
        )}
        <Spacer height={30} />
      </IonList>

      <IonButton
        fill="outline"
        onClick={() => {
          onComplete();
          window.location.reload();
        }}
        style={{
          margin: "15px 10px 15px auto",
        }}
      >
        Save Changes
      </IonButton>
      {authorId && group && group.members && !group.members.includes(authorId) && <IonButton
        fill="outline"
        color="tertiary"
        onClick={() => {
          authorId && changeGroup(ownerId, groupId, "members", [authorId, ...group.members])
          authorId && setGroup({ ...group, members: [authorId, ...group.members] })
        }}
        style={{
          margin: "15px auto 15px 10px",
        }}
      >
        Join  Group
      </IonButton>}
      {authorId && group.members.includes(authorId) && <IonButton
        fill="outline"
        color="tertiary"
        onClick={() => {
          const members = [...group.members.filter(x => x !== authorId)];
          authorId && changeGroup(ownerId, groupId, "members", members)
          authorId && setGroup({ ...group, members })
        }}
        style={{
          margin: "15px auto 15px 10px",
        }}
      >
        Leave  Group
      </IonButton>}
      <IonItem>

        <IonButtons slot="end">
          <IonButton
            fill="outline"
            color="danger"
            onClick={() => setShowAlert("delete group")}
            style={{
              margin: "15px auto 15px 10px",
            }}
          >
            Delete Group
          </IonButton>
        </IonButtons>
      </IonItem>

      <ConfirmationAlert
        message={`Do you want to delete ${group.title} ?`}
        onConfirm={handleDeleteGroup}
        onDismiss={() => setShowAlert("")}
        isAlertOpen={showAlert === "delete group"}
      />
    </>
  );
};

export default GroupForm;
