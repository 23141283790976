import { IonButton, IonIcon, IonLabel, IonToggle } from "@ionic/react";
import { funnelOutline } from "ionicons/icons";
import { useProfile } from "../data/store/useProfile";
import React from "react"
export const CompactToggle:React.FC=()=>{
    const { compact, setCompactMode} = useProfile();

    return <div className="sortWrapper">

        <IonLabel color={compact ? "primary" : "medium"}>
          Compact view
        </IonLabel>
        <IonToggle
        onIonChange={(e)=>{
            setCompactMode(e.detail.checked)
        }}
          class="ion-padding-start"
          checked={compact}
        />
    </div>

}