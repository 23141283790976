import React, { useEffect } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";
import { toDate, useIdParam, usePageTitle } from "../util/sugar";

import Content from "../components/Content";
import PleaseLoginCard from "../components/PleaseLoginCard";
import RequestAccessCard from "../components/RequestAccessCard";
import SongItem from "../components/SongItem";

const SongDetail: React.FC = (props) => {
  const id = useIdParam(props);
  const { shouldFetch, fetch } = useSongs();
  const song = useSongs((x) => x.retrieve(id));
  const unauthenticated = useProfile((x) => x.status === "unauthenticated");
  const authorId = useProfile((x) => x.authorId);
  const isAlumni = useConfig((x) => x.isAlumni());

  const songTitle = song
    ? song.title + " " + toDate(song.updatedAt)
    : "loading";

  usePageTitle(songTitle);

  useEffect(() => {
    shouldFetch(id) && fetch(id);
  }, [id, shouldFetch, fetch]);

  if (isAlumni === false) {
    return (
      <Content>
        <RequestAccessCard authorId={authorId} />
      </Content>
    );
  } else if (unauthenticated) {
    return (
      <Content>
        <PleaseLoginCard action="see this page" />
      </Content>
    );
  }

  return (
    <Content className="background-wall">
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            {song && <SongItem key={song.id} id={song.id}></SongItem>}
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default SongDetail;
