import {
  IonBadge,
  IonButton,
  IonButtons, IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent, IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonRouterLink,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { notificationsOutline, searchOutline } from 'ionicons/icons';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useNotifications } from '../data/store/useNotifications';
import { useProfile } from '../data/store/useProfile';
import { useSearch } from '../data/store/useSearch';
import { useTitle } from '../data/store/useTitle';
import useWindowSize from '../util/useWindowSize';
import { AllRoutes, Pages } from './Menu';
import SongSearchResultItem, { ArtistSearchResultItem } from './SearchResult';


const TopToolbar: React.FC = () => {
  const { width } = useWindowSize();
  const md = width > 600;
  const { authenticated } = useProfile();
  const { notifications } = useNotifications();
  const hasNotifications = notifications.length !== 0;
  const { query, nbPages } = useSearch();
  const title = useTitle(x => x.title);
  const { search, songs, artists } = useSearch();
  const searchRef = useRef<HTMLIonSearchbarElement>(null);
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const hideSearch = path.includes('transmit') || path.includes('conversations');
  const searchIsVisible = !hideSearch && showSearch;
  const [searchState, setSearchState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      page: 0,
      query: '',
      searchContext: 'songs',
      disabled: true
    }
  );

  function renderMenuItems(list: Pages[]) {
    return list.map((p) => (
      <IonButton
        key={p.path}
        color={location.pathname === (p.path) ? 'tertiary' : 'gray'}
        routerLink={p.path}
        routerDirection={p.routerDirection}
      >
        <IonIcon slot='start' icon={p.icon} />
        <IonLabel>{p.title}</IonLabel>
      </IonButton>
    ));
  }

  const setSearchContext = (searchContext: string) => {
    setSearchState({
      page: 0,
      searchContext,
      disabled: false
    });
  };

  const makeSearch = (query: string) => {
    setSearchState({
      page: 0,
      query,
      disabled: false
    });
  };

  const fetchMore = (e: any) => {
    const nextPage = searchState.page + 1;
    setSearchState({ page: nextPage });
    if (searchState.page <= nbPages) {
      e.target.complete();
    } else {
      setSearchState({ disabled: true });
    }
  };

  useEffect(() => {
    search(searchState.query, searchState.searchContext, searchState.page)
  }, [search, searchState.page, searchState.query, searchState.searchContext]);

  useEffect(() => {
    if (query === undefined) {
      search('');
      setShowSearch(false);
    }
  }, [query, search]);

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot='start'>
          <IonMenuButton id='main' />
          {!hideSearch && (
            <IonButton
              onClick={() => {
                setShowSearch(x => !x);
                setTimeout(() => {
                  searchRef.current && searchRef.current.setFocus();
                }, 100);
              }}
            >
              <IonIcon icon={searchOutline} color={showSearch ? 'tertiary' : 'primary'} />
            </IonButton>
          )}
          {md && renderMenuItems(AllRoutes.topLeftPages)}
        </IonButtons>
        <IonTitle className='ion-text-center'>{title}</IonTitle>
        <IonButtons slot='end'>
          {authenticated && hasNotifications && (
            <IonRouterLink routerLink='/notices'>
              <IonIcon color='success' icon={notificationsOutline} />
              <IonBadge color='success'>{notifications.length}</IonBadge>
            </IonRouterLink>
          )}
          {md && (
            <>
              {authenticated
                ? renderMenuItems(AllRoutes.topMenuLoggedInPages)
                : renderMenuItems(AllRoutes.loggedOutPages)
              }
            </>
          )}
        </IonButtons>
      </IonToolbar>
      {searchIsVisible && (
        <IonToolbar style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 15 }}>
          <IonItem
            lines='none'
            className='ion-align-items-center'
            style={{
              borderRadius: 10,
              '--background': 'rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07)',
              '--padding-start': 0,
              '--inner-padding-end': 0
            }}
          >
            <IonSearchbar
              ref={searchRef}
              className='ion-no-padding'
              style={{
                height: 36,
                paddingBottom: 0,
                '--background': 'transparent',
                '--border-radius': 0
              }}
              debounce={200}
              placeholder='Search'
              onIonChange={(e) => makeSearch(e.detail.value!)}
            />
            <IonItem
              lines='none'
              className='search-divider'
            />
            <IonSelect
              slot='end'
              style={{ '--padding-top': '8px', '--padding-bottom': '8px' }}
              value={searchState.searchContext}
              onIonChange={(e) => setSearchContext((e.target as any).value)}
            >
              <IonSelectOption value='songs'>Song</IonSelectOption>
              <IonSelectOption value='artists'>Artist</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonToolbar>
      )}
      {searchIsVisible && (!!songs.length || !!artists.length) && (
        <IonContent style={{ height: (songs.length || artists.length) * 52, maxHeight: 400 }}>
          <IonList>
            {artists && artists.length > 0 && artists.map(artist => (
              <ArtistSearchResultItem key={artist.id} {...artist} />
            ))}
            {songs && songs.length > 0 && songs.map(song => (
              <SongSearchResultItem key={song.id} {...song} />
            ))}
          </IonList>
          <IonInfiniteScroll
            threshold='104px'
            onIonInfinite={fetchMore}
            disabled={searchState.disabled || searchState.page === nbPages}
          >
            <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading more data...">
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      )}
    </IonHeader>
  );
};

export default React.memo(TopToolbar);
