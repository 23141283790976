import { firestore } from "firebase/app";
import create from "zustand";
import { Role } from "../../models/Role";

export type RoleState = {
  role: Role;
  allUsersWithRoles: any;
  status: "idle" | "observing";
  isLoading: boolean;
  getUsersWithRoles: () => void;
  observe: (ownerId: string) => void;
  setUserRole: (ownerId: string, role: Role) => void;
  deleteUserRole: (ownerId: string) => void;
};

export const useRoles = create<RoleState>((set) => ({
  role: {} as Role,
  status: "idle",
  isLoading: false,
  allUsersWithRoles: [],
  getUsersWithRoles: () => {
    return new Promise(() => {
      set({ isLoading: true });
      const db = firestore();

      db.collection("roles")
        .get()
        .then((doc) => {
          const allUsersWithRoles: any = [];
          doc.forEach((doc) => {
            allUsersWithRoles.push({
              ownerId: doc.id,
              authority: doc.data().authority,
            });
          });

          set({
            allUsersWithRoles,
            isLoading: false,
          });
        })
        .catch((error) => console.error(error));
    });
  },
  observe: (ownerId) => {
    set({ status: "observing" });
    const db = firestore();
    db.collection("roles")
      .doc(ownerId)
      .onSnapshot((doc) => {
        const role = { ...doc.data(), id: doc.id } as Role;
        set({ role });
      });
    //        GroupService.observe(ownerId)
  },
  setUserRole: (ownerId: string, role) => {
    if (!ownerId) {
      console.error("Incorrect ownerId", ownerId);
      return;
    }

    return new Promise<void>((resolve, reject) => {
      const db = firestore();
      db.collection("roles").doc(ownerId).set(role).then(resolve).catch(reject);
    });
  },
  deleteUserRole: (ownerId: string) => {
    if (!ownerId) {
      console.error("Incorrect ownerId", ownerId);
      return;
    }

    return new Promise<void>((resolve, reject) => {
      const db = firestore();
      db.collection("roles").doc(ownerId).delete().then(resolve).catch(reject);
    });
  },
}));
