import React, { useEffect, useState } from "react";
import { IonBadge, IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonLoading, IonRow, IonText, IonTitle, IonToggle } from "@ionic/react";

import Content from "../components/Content";
import PleaseLoginCard from "../components/PleaseLoginCard";
import RequestAccessCard from "../components/RequestAccessCard";
import SongList from "../components/SongList";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";

import { toDate, useIdParam, usePageTitle } from "../util/sugar";
import isEmpty from "lodash.isempty";
import { funnelOutline, pause, pauseCircle, play, playCircle } from "ionicons/icons";
import CompactSongList from "../components/CompactSongList";
import { SongService } from "../services/SongService";
import { firestore } from "firebase";
import { SongRef } from "../models/Song";
import ArtistButton from "./ArtistButton";
import { usePlayer } from "../data/store/usePlayer";
import { usePlaylist } from "../data/store/usePlaylist";
import MediaService from "../services/MediaService";
import { useAssignments } from "../data/store/useAssignments";

const GroupSongsSheet: React.FC = (props) => {
  const id = useIdParam(props);
  const { observe } = useSongs();
  const { authorId, ownerId } = useProfile();
  const { groups } = useConfig();
  const unauthenticated = useProfile((x) => x.status === "unauthenticated");
  const authenticated = useProfile((x) => x.status === "authenticated");
  const fetchSome = useSongs((x) => x.fetchSome);
  const status = useSongs((x) => x.status);
  const songs = useSongs((x) => x.timeline);

  const membership = useConfig((x) => x.activeMemberships());
  const hasGroups = useConfig(
    (x) => x.membership.length !== 0 && typeof x.membership !== "undefined"
  );
  const loadingGroups = useConfig((x) => typeof x.membership === "undefined");
  const retrieveSong = useSongs((x) => x.retrieve);

  const [fetchedInitial, setFetchedInitial] = useState(false);

  const groupSongs = songs.filter((songRef) => {
    const song = retrieveSong(songRef.id);
    return song && song.groups.includes(id);
  });

  const isMember =
    (!isEmpty(id) && membership.includes(id)) ||
    (groups[id] && groups[id].ownerId === ownerId);

  usePageTitle("Session Songs");
  const assignments = useAssignments(x=>x.assignments)
  const { queueSong,setNowPlaying,nowPlayingId } = usePlaylist();
const {isPaused}=usePlayer();
  const [groupSongRefs,setGroupSongRefs]=useState<SongRef[]>([])
  useEffect(()=>{
    if(typeof id==='undefined'){
      return;
    }
      const db = firestore()
      db.collection('songs')
        .where('groups', 'array-contains', id)
        .orderBy('updatedAt', 'desc')
        .limit(1000)
        .get()
        .then((querySnapshot) => {
          setGroupSongRefs(querySnapshot.docs.map(SongService.StronglyTypeSongData).sort((a,b)=>{return a.updatedAt.seconds-b.updatedAt.seconds}))
      })
  },[id])
  useEffect(() => {
    if (authenticated && hasGroups && id && !fetchedInitial && isMember) {
      observe([id]);
      fetchSome([id]);
      setFetchedInitial(true);
    }
  }, [
    isMember,
    authenticated,
    fetchSome,
    hasGroups,
    id,
    membership,
    observe,
    fetchedInitial,
  ]);

  if ((!hasGroups && !loadingGroups) || !isMember) {
    return (
      <Content>
        <RequestAccessCard authorId={authorId} />
      </Content>
    );
  } else if (unauthenticated) {
    return (
      <Content>
        <PleaseLoginCard action="" />
      </Content>
    );
  }
  
  return (
    <Content className="no-scroll">
      <IonLoading isOpen={status === "initial"} />
{groupSongRefs.map((ref:any)=>{
 return <IonItem key={ref.id} onClick={()=>{

  if(nowPlayingId===ref.id&&!isPaused){
    queueSong(ref.id)
    MediaService.please().pause()
  }else{
    queueSong(ref.id)
    MediaService.please()
    .setSong(ref)
    .then(() => {
      MediaService.please().play();
      setNowPlaying(ref.id);
    });
 }}}>


{nowPlayingId===ref.id&&!isPaused?<IonButton color="clear" onClick={(()=>{
queueSong(ref.id)
MediaService.please()
.pause()
})}>
<IonIcon color='primary' icon={pause}/>  
<IonText color='medium'>
{ref.title}
</IonText>
{assignments[ref.assignment]}
</IonButton>:<IonButton color="clear" onClick={(()=>{
queueSong(ref.id)
MediaService.please()
.setSong(ref)
.then(() => {
  MediaService.please().play();
  setNowPlaying(ref.id);
});

})}>
<IonIcon color='favorite' icon={play}/>  
<IonText color='medium'>
{ref.title}
</IonText>
</IonButton>}
<IonButtons slot='end'>
{Object.values(ref.artists).map(artist=><ArtistButton artist={artist as any}/>)}
<IonBadge>
  {toDate(ref.updatedAt)}
</IonBadge> 
</IonButtons>
 
 </IonItem>
})}
    </Content>
  );
};

export default GroupSongsSheet;
