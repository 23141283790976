import React, { useEffect, useState } from 'react'

import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonItem,
  IonProgressBar,
  IonText,
  IonTitle,
} from '@ionic/react'
import { useArtists } from '../data/store/useArtists'
import { useConfig } from '../data/store/useConfig'
import { useProfile } from '../data/store/useProfile'
import { ArtistId } from '../models/Artist'
import AsyncArtistButton from './AsyncArtistButton'
import { logInOutline } from 'ionicons/icons'
import { LoginService } from '../services/LoginService'
import Spacer from './Spacer'

const defaultMessage =
  "You haven't joined any active groups yet. To join a group, find the invitation link & passcode to your group (this was sent out in an email from song share and also is listed on your course information page)."

const RequestAccessCard: React.FC<{
  authorId?: ArtistId
  message?: string
}> = ({ message }) => {
  const { authenticated } = useProfile()
  const loginStatus = useProfile((x) => x.status)
  const id = useProfile((x) => x.authorId)
  const hasGroups = useConfig(
    (x) => x.membership.length > 0 && status === 'observing',
  )
  const membership = useConfig((x) => x.activeGroups())
  const inactiveGroups = useConfig((x) => x.inactiveGroups())
  const { status } = useConfig()
  const {} = useConfig()
  const artist = useArtists((x) => x.retrieve(id || ''))

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])
  if (loginStatus !== 'unknown' && !authenticated) {
    return (
      <IonCard>
        <IonCardHeader>
          <IonItem lines="none">
            <IonAvatar>
              <IonImg src="/assets/icon/apple-touch-icon.png" />
            </IonAvatar>
            <IonText>song share</IonText>
          </IonItem>
        </IonCardHeader>
        <IonCardContent>
          <IonButton
            color="primary"
            routerLink="/login"
            type="submit"
            expand="block"
          >
            Navigate to Login to continue
          </IonButton>
        </IonCardContent>
      </IonCard>
    )
  }
  return (
    <IonCard>
      <IonCardContent>
        {loading ? (
          <>
            Loading <IonProgressBar type="indeterminate" />
          </>
        ) : (
          <>
            {id && <AsyncArtistButton id={id} />}
            <IonBadge color="success">
              {membership.length} active groups
            </IonBadge>{' '}
            <IonBadge color="secondary">
              {inactiveGroups.length} inactive groups
            </IonBadge>{' '}
            <IonCard color="secondary">
              <IonCardContent>
                <IonText color="dark">
                  {membership.length === 0 && defaultMessage}
                </IonText>
              </IonCardContent>
            </IonCard>
          </>
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default RequestAccessCard
