import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonItem,
  IonItemDivider,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { firestore } from "firebase";

import AsyncArtistButton from "../components/AsyncArtistButton";
import Config from "../components/Config";
import Content from "../components/Content";
import GroupAdmin from "../components/GroupAdmin";
import RolesPanel from "../components/RolesPanel";
import Issues from "./Issues";

import { useArtists } from "../data/store/useArtists";
import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useRoles } from "../data/store/useRoles";
import { useToasts } from "../data/store/useToasts";

import { ArtistId } from "../models/Artist";
import { Group } from "../models/Group";
import { Song, SongId } from "../models/Song";
import { OwnerId } from "../models/User";
import { SongService } from "../services/SongService";
import { toDate, usePageTitle } from "../util/sugar";

interface OwnerParams {
  ownerId: OwnerId;
}

const NotAuthorized: React.FC<OwnerParams> = ({ ownerId }) => (
  <IonCard>
    <IonCardHeader color="primary">
      <IonCardTitle>
        <IonTitle>Group Status</IonTitle>
      </IonCardTitle>
    </IonCardHeader>

    <IonCardContent>
      <IonItemDivider color="clear" />
      <IonText>Your account is missing administrative authority.</IonText>
      <IonItemDivider color="clear" />
      <IonText>
        If this is an error, please
        <IonButton routerLink={"/issue/group/" + ownerId}>
          {" "}
          Report an Issue
        </IonButton>
      </IonText>
    </IonCardContent>
  </IonCard>
);

const UsersAdmin: React.FC = () => {
  const { fetchAll, all } = useArtists();

  useEffect(() => {
    fetchAll();
  }, [all, fetchAll]);

  return (
    <IonCard>
      <IonCardHeader color="primary">
        <IonCardTitle>
          <IonTitle>Users</IonTitle>
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        {all.map((artistId) => (
          <AsyncArtistButton variant="alias" key={artistId} id={artistId} />
        ))}
        <IonItemDivider />
        {all.length} Total
      </IonCardContent>
    </IonCard>
  );
};

const Modify: React.FC = () => {
  const [songs, setSongs] = useState<Song[]>([]);
  const { post } = useToasts();
  const groups = useConfig((x) => x.groups);

  const addUserAndSongToGroup = (
    songId: SongId,
    authorIds: ArtistId[],
    group: Group
  ) => {
    const db = firestore();
    db.collection("songs")
      .doc(songId)
      .update({ groups: [group.id] })
      .then(() => {
        db.collection("groups")
          .doc(group.id)
          .update({ members: [...authorIds, ...group.members] })
          .then(() => {
            post({
              icon: "",
              id: songId,
              message: "Successfully added User and song to " + group.title,
            });
          })
          .catch(() => {
            post({
              icon: "",
              id: songId,
              message: "You don't have permissions to add a user to a group",
            });
          });
      })
      .catch(() => {
        post({
          icon: "",
          id: songId,
          message: "You don't have permissions to modify a users song",
        });
      });
  };

  useEffect(() => {
    SongService.fetchSome(1000, ["public"])
      .then((songResults) => {
        setSongs(songResults);
      })
      .catch(() => {
        alert("error");
      });
  }, []);

  return (
    <Content>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Songs in Public Group</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <IonList>
            {songs.map((song) => (
              <IonItem>
                <IonButtons slot="end">
                  <IonButton target="_blank" href={"song/" + song.id}>
                    {song.title}
                  </IonButton>
                  <IonChip>{toDate(song.updatedAt)}</IonChip>
                </IonButtons>

                <IonSelect
                  interface="action-sheet"
                  placeholder="Select Group"
                  onIonChange={(e) => {
                    const group = groups[e.detail.value!];
                    addUserAndSongToGroup(song.id, song.authors, group);
                  }}
                >
                  {Object.keys(groups).map((id) => (
                    <IonSelectOption value={id}>
                      {groups[id].title}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {song.authors.map((artistId) => (
                  <AsyncArtistButton id={artistId} />
                ))}
              </IonItem>
            ))}
          </IonList>
        </IonCardContent>
      </IonCard>
    </Content>
  );
};

const Admin: React.FC = () => {
  const { ownerId } = useProfile();
  const { role } = useRoles();
  const authority = role && role.authority;
  const isSuperAdmin = authority && authority.includes("super admin");
  const isAdmin = authority && authority.includes("admin");
  const isModerator = authority && authority.includes("moderator");
  const hasDashboard = isSuperAdmin || isAdmin || isModerator;

  const [showIssues, setShowIssues] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showRolesPanel, setShowRolesPanel] = useState(false);
  const [showModify] = useState(false);

  usePageTitle("Admin");
  console.log(authority,hasDashboard);

  if (!ownerId || !role) {
    return (
      <Content>
        <IonTitle>
          <IonSpinner name="bubbles" />
        </IonTitle>
      </Content>
    );
  }

  return (
    <Content>
      <IonGrid>
        <IonRow>
          {hasDashboard ? (
            <IonCol size="12" sizeLg="8" offsetLg="2">
              <IonToolbar>
                <IonButton
                  fill="solid"
                  color={!showIssues ? "tertiary" : "favorite"}
                  onClick={() => {
                    setShowIssues((x) => !x);
                  }}
                >
                  Issues
                </IonButton>

                <IonButton
                  fill="solid"
                  color={!showUsers ? "tertiary" : "favorite"}
                  onClick={() => {
                    setShowUsers((x) => !x);
                  }}
                >
                  Users
                </IonButton>

                {(isSuperAdmin || isAdmin) && (
                  <>
                    <IonButton
                      fill="solid"
                      color={!showGroups ? "tertiary" : "favorite"}
                      onClick={() => {
                        setShowGroups((x) => !x);
                      }}
                    >
                      Groups
                    </IonButton>

                    <IonButton
                      fill="solid"
                      color={!showRolesPanel ? "tertiary" : "favorite"}
                      onClick={() => {
                        setShowRolesPanel((x) => !x);
                      }}
                    >
                      Permissions
                    </IonButton>

                    <IonButton
                      fill="solid"
                      color={!showConfig ? "tertiary" : "favorite"}
                      onClick={() => {
                        setShowConfig((x) => !x);
                      }}
                    >
                      Public Config
                    </IonButton>
                  </>
                )}
              </IonToolbar>
              {showGroups && <GroupAdmin />}
              {showUsers && <UsersAdmin />}
              {showIssues && <Issues />}
              {showConfig && <Config />}
              {showModify && <Modify />}
              {showRolesPanel && <RolesPanel />}
            </IonCol>
          ) : (
            <IonCol size="12" sizeLg="8" offsetLg="2">
              {ownerId ? (
                <NotAuthorized ownerId={ownerId} />
              ) : (
                <IonButton routerLink="/login">Login</IonButton>
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default Admin;
