import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  alertOutline,
  calendarOutline,
  closeOutline,
  downloadOutline,
  folderOpenOutline,
  listOutline,
  pencilOutline,
  personAddOutline,
  personRemove,
  removeOutline,
  shareSocialOutline,
  starOutline,
  starSharp,
} from "ionicons/icons";

import { useConfig } from "../data/store/useConfig";
import { useContextModals } from "../data/store/useContextModals";
import { useFavorites } from "../data/store/useFavorites";
import { useProfile } from "../data/store/useProfile";
import { useToasts } from "../data/store/useToasts";
import { useRoles } from "../data/store/useRoles";

import AvailablePlaylists from "./AvailablePlaylists";
import copyTextToClipboard from "../util/copyToClipBoard";
import get from "lodash.get";
import AvailableAlbums from "./AvailableAlbums";

const SongMenuModal: React.FC = () => {
  const {
    addFavorite,
    isFavorite,
    removeFavorite,
    isFollowing,
    follow,
    unfollow,
  } = useFavorites();
  const { ownerId, authorId } = useProfile();
  const { isShowSongModal, modalSong, showSongModal } = useContextModals();
  const clear = useContextModals((x) => x.clearSongModalContext);
  const { role } = useRoles((state) => state);
  const { post } = useToasts();
  const [playlistPopover, setPlaylistPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [albumPopover, setAlbumPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const songId = modalSong && modalSong.id;
  const originalId = (modalSong && modalSong.original) || songId;
  const isHasDashboard = get(role, "authority", []).some((item) => {
    return item === "admin" || item === "super admin";
  });
  const isOwner = authorId && modalSong && modalSong.ownerId === ownerId;
  const isNotOwnerButAdmin = isHasDashboard && !isOwner;
  const authorsData =
    modalSong &&
    modalSong.authors &&
    modalSong.authors.map((id) => modalSong.artists[id]);
  const groups = useConfig(
    (x) =>
      (modalSong &&
        modalSong.groups &&
        modalSong.groups.map((id) => x.groups[id] || { title: "", id })) ||
      []
  );

  const closeAndClear = () => {
    clear();
    showSongModal(false);
  };

  const onDismissModalWindow = () => {
    showSongModal(false);
  };

  const closePlaylistsPopover = () => {
    setPlaylistPopover({
      showPopover: false,
      event: undefined,
    });
  };

  const closeAlbumsPopover = () => {
    setAlbumPopover({
      showPopover: false,
      event: undefined,
    });
  };

  return (
    <IonModal isOpen={isShowSongModal} onDidDismiss={onDismissModalWindow}>
      {modalSong && (
        <IonList>
          <IonToolbar color="secondary">
            <IonTitle color="dark">{modalSong.title}</IonTitle>
            <IonButtons slot="end">
              <IonButton color="dark" onClick={closeAndClear}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>

          {groups &&
            groups.map(
              (group) =>
                !!group.title.length && (
                  <IonItem
                    key={group.id}
                    onClick={closeAndClear}
                    routerLink={"/session-songs/" + group.id}
                  >
                    <IonIcon slot="start" icon={calendarOutline} />
                    <IonLabel>{group.title}</IonLabel>
                  </IonItem>
                )
            )}

          {authorId &&
            modalSong.authors &&
            modalSong.authors.includes(authorId) && (
              <>
                <IonItem
                  href={modalSong.media.src}
                  download={modalSong.title}
                  detail={false}
                >
                  <IonIcon slot="start" icon={downloadOutline} />
                  <IonLabel>Download</IonLabel>
                </IonItem>
              </>
            )}

          {ownerId &&
            authorsData &&
            authorsData.map((author) => {
              const following = isFollowing(author.id);
              return (
                <IonItem
                  key={author.id}
                  detail={false}
                  routerLink="#"
                  onClick={() =>
                    following
                      ? unfollow(ownerId, author.id)
                      : follow(ownerId, author.id)
                  }
                >
                  {following ? (
                    <IonIcon slot="start" color="primary" icon={personRemove} />
                  ) : (
                    <IonIcon slot="start" icon={personAddOutline} />
                  )}
                  <IonLabel>
                    {following ? "Unfollow" : "Follow"} {author.alias}
                  </IonLabel>
                </IonItem>
              );
            })}

          {ownerId && songId && (
            <IonItem
              detail={false}
              routerLink="#"
              onClick={() => {
                isFavorite(ownerId, songId)
                  ? removeFavorite(ownerId, songId)
                  : addFavorite(ownerId, songId);
              }}
            >
              {isFavorite(ownerId, songId) ? (
                <IonIcon slot="start" color="primary" icon={starSharp} />
              ) : (
                <IonIcon slot="start" icon={starOutline} />
              )}
              <IonLabel>
                {isFavorite(ownerId, songId) ? "Remove from " : "Add to "}
                Favorites
              </IonLabel>
            </IonItem>
          )}

          {songId && (
            <>
              <IonItem
                routerLink="#"
                onClick={(event: any) => {
                  event.persist();
                  setPlaylistPopover({ showPopover: true, event });
                }}
              >
                <IonIcon slot="start" icon={listOutline} />
                <IonLabel>Add to Playlist</IonLabel>
              </IonItem>

              {playlistPopover.showPopover && (
                <AvailablePlaylists
                  popoverState={playlistPopover}
                  popoverDismiss={closePlaylistsPopover}
                  onClose={onDismissModalWindow}
                  nowPlayingId={songId}
                />
              )}
            </>
          )}

          {songId && modalSong.ownerId === ownerId && (
            <>
              <IonItem
                routerLink="#"
                onClick={(event: any) => {
                  event.persist();
                  setAlbumPopover({ showPopover: true, event });
                }}
              >
                <IonIcon slot="start" icon={folderOpenOutline} />
                <IonLabel>Add to Album</IonLabel>
              </IonItem>

              {albumPopover.showPopover && (
                <AvailableAlbums
                  popoverState={albumPopover}
                  popoverDismiss={closeAlbumsPopover}
                  onClose={onDismissModalWindow}
                />
              )}
            </>
          )}

          {songId && (
            <IonItem
              detail={false}
              routerLink="#"
              onClick={() => {
                copyTextToClipboard(`${window.location.origin}/song/${songId}`);
                post({
                  id: songId,
                  message: `link for ${modalSong.title} copied to clipboard`,
                  icon: shareSocialOutline,
                });
                closeAndClear();
              }}
            >
              <IonIcon slot="start" icon={shareSocialOutline} />
              <IonLabel>Share Song</IonLabel>
            </IonItem>
          )}

          <IonItem
            routerLink={`/issue/comments/${songId}`}
            onClick={closeAndClear}
          >
            <IonIcon slot="start" icon={alertOutline} />
            <IonLabel>Report Comments</IonLabel>
          </IonItem>

          {(isOwner || isHasDashboard) && (
            <>
              <IonItem
                onClick={onDismissModalWindow}
                routerLink={`/edit-song/${originalId}`}
              >
                <IonIcon slot="start" icon={pencilOutline} />
                <IonLabel color={isNotOwnerButAdmin ? "danger" : "dark"}>
                  Edit Song Information
                </IonLabel>
              </IonItem>
              <IonItem
                onClick={closeAndClear}
                routerLink={`/delete-song/${originalId}`}
              >
                <IonIcon slot="start" icon={removeOutline} />
                <IonLabel color={isNotOwnerButAdmin ? "danger" : "dark"}>
                  Delete
                </IonLabel>
              </IonItem>
            </>
          )}
        </IonList>
      )}
    </IonModal>
  );
};

export default SongMenuModal;
