import React, { useRef, useState } from "react";
import firebase from "firebase";
import {
  IonButton,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonProgressBar,
  IonIcon,
  IonText,
} from "@ionic/react";
import {
  closeOutline,
  musicalNotes,
  refreshCircleOutline,
} from "ionicons/icons";

import { ArtistId } from "../models/Artist";
import { MediaReference, Song } from "../models/Song";
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { uuidv4 } from "../util/sugar";

import "react-dropzone-uploader/dist/styles.css";

interface StateProps {
  ownerId?: ArtistId;
}

interface OwnProps {
  message?: string;
  collection: string;
  initialAudio?: Song;
  onToggle?: () => void;
  onComplete: (media: MediaReference) => void;
}

interface DispatchProps {}

interface UploaderProps extends OwnProps, StateProps, DispatchProps {}

const ConvertingUploader: React.FC<UploaderProps> = ({
  onComplete,
  onToggle = () => {},
  initialAudio = {},
}) => {
  const isInitialAudioExist = !isEmpty(initialAudio);
  const [isInitialMedia, setInitialMedia] = useState(isInitialAudioExist);
  const [isShowDownloadInput, setIsShowDownloadInput] = useState(
    !isInitialAudioExist
  );
  const [status, setStatus] = useState<string>();
  const uploadRef = useRef<any>();

  const renderTitle = () => {
    if (isShowDownloadInput) {
      return <IonText color="success">Return previous song</IonText>;
    } else {
      return (
        <>
          <IonIcon icon={musicalNotes} style={{ paddingRight: 10 }} />
          <IonText color="success">{`${initialAudio.title}.${get(
            initialAudio,
            "media.type",
            "mp3"
          )}`}</IonText>
        </>
      );
    }
  };

  const toggleEdit = () => {
    onToggle();
    setIsShowDownloadInput((prevState) => !prevState);
  };

  const handleChange = () => {
    const file = uploadRef.current.files[0];
    setInitialMedia(false);

    if (!file) {
      return;
    }

    const extension = file.name.split(".").pop();
    const song_name = "song" + uuidv4();
    const file_name = song_name + "." + extension;
    const isMP3 = String(extension).toLowerCase() === "mp3";
    const mp3_file_name = song_name + "_output.mp3";
    const isVideo = String(extension).toLowerCase() === "mp4"||String(extension).toLowerCase() === "mov";
    
    const storageRef = isMP3
      ? firebase.storage().ref(mp3_file_name):firebase.storage().ref(file_name);

    setStatus("uploading");

    storageRef.put(file).then(() => {
      const waitForConversion = setInterval(() => {
        firebase
          .storage()
          .ref(isVideo?file_name:mp3_file_name)
          .getDownloadURL()
          .then((uri) => {
            if (uri) {
              setStatus("uploaded");
              onComplete({
                src: uri,
                type: isVideo?"video":"audio/mpeg",
              });
              clearInterval(waitForConversion);
            }
          });
      }, 2000);
    });
  };

  return (
    <>
      <IonItem>
        <IonLabel position="stacked" color="primary">
          Media
        </IonLabel>

        {isInitialMedia && (
          <IonButton
            onClick={toggleEdit}
            size="default"
            color="dark"
            fill="clear"
          >
            {renderTitle()}

            {isShowDownloadInput ? (
              <IonIcon
                icon={refreshCircleOutline}
                size="big"
                style={{ paddingLeft: 10 }}
              />
            ) : (
              <IonIcon
                icon={closeOutline}
                size="big"
                style={{ paddingLeft: 10 }}
              />
            )}
          </IonButton>
        )}

        {isShowDownloadInput && (
          <input
            accept="audio/mp3,audio/wav,audio/m4a,audio/x-m4a,audio/x-aac,video/*"
            onChange={handleChange}
            style={{ width: "100%", height: "100%" }}
            ref={uploadRef}
            type="file"
          />
        )}
      </IonItem>

      <IonItemDivider color="light">{status}</IonItemDivider>

      {status === "uploading" && (
        <IonProgressBar color="tertiary" type="indeterminate" />
      )}
    </>
  );
};

export default ConvertingUploader;
