import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTitle } from "../data/store/useTitle";
import { SpecifiedDate, Timestamp } from "../models/Timeline";
import { PlaylistOrder } from "../models/Artist";
import { Playlist } from "../models/Playlists";
import isEmpty from "lodash.isempty";
import { Song } from "../models/Song";
import { SongService } from "../services/SongService";
import { Group, GroupId } from "../models/Group";

export const sanitize = (unsafe: string) => {
  return unsafe.toLowerCase().replace("javascript", "");
};

export const externalLink = (safe: string) => {
  if (safe.startsWith("http")) {
    return safe;
  } else {
    return "http://" + safe;
  }
};

export const findHashtags = (text: string) => {
  const tags: RegExpMatchArray | "" | null = text && text.match(/#[\p{L}]+/giu);
  return (
    tags !== null &&
    tags !== "" &&
    tags.map((tag) => tag.slice(1).toLowerCase())
  );
};

export const trimToTen = (array: Array<string>) => {
  return array.length > 10 ? array.slice(0, 10) : array;
};

export const usePageTitle = (pageTitle: string) => {
  const { setTitle, title } = useTitle();
  useEffect(() => {
    if (title !== pageTitle) {
      setTitle(pageTitle || "");
    }
  });
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const useIdParam = (props: React.PropsWithChildren<any>) =>
  props.id || props.history.location.pathname.split("/").pop();
export const getIdParamFromProps = useIdParam;

export const useSearchParam = (param: string) => {
  const { searchParams } = new URL(document.location as any);
  return searchParams.get(param);
};

export const useCssImage = (url: string | undefined): string => {
  if (url === undefined) {
    return "";
  }
  return url && "url(" + url + ") ";
};

export const usePathName = (props: React.PropsWithChildren<any>) =>
  capitalize(props.location.pathname.split("/")[1]);

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const addBodyClass = (className: string) =>
  document.body.classList.add(className);
const removeBodyClass = (className: string) =>
  document.body.classList.remove(className);

export function useBodyClass(className: string) {
  useEffect(() => {
    // Set up
    addBodyClass(className);

    // Clean up
    return () => {
      removeBodyClass(className);
    };
  }, [className]);
}

export const compareTimestamps = (a: Timestamp, b: Timestamp) => {
  return a.seconds - b.seconds;
};

export const toTimestamp = (date: number) => {
  return {
    seconds: date / 1000,
    nanoseconds: 0,
  } as Timestamp;
};

export const toDate = (time: Timestamp) => {
  const date = dayjs(toDateSeconds(time));
  return date.format("MM/DD/YYYY");
};
export const toDateTime = (time: Timestamp) => {
  const date = dayjs(toDateSeconds(time));
  return date.format("MM/DD/YYYY/ (HH:ss)");
};

export const toDateSeconds = (timestamp: Timestamp) => {
  return timestamp && timestamp.seconds && timestamp.seconds * 1000;
};

export const shuffle = (items: any) => {
  // Fisher–Yates shuffle
  const arr = [...items];
  let currentIndex = arr.length,
    randomIndex;

  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [arr[currentIndex], arr[randomIndex]] = [
      arr[randomIndex],
      arr[currentIndex],
    ];
  }

  return arr;
};

/**
 * Make an array unique
 * @param ray
 */
export const uniq = (ray: any[]) => {
  return [...new Set(ray)];
};

export const changeArrayItemsOrder = (
  orderedArray: PlaylistOrder[] | undefined,
  arrayGroupedById: Record<string, Playlist>,
  array: Playlist[]
): Playlist[] => {
  if (isEmpty(orderedArray) || !orderedArray) return array;

  const playlistsWithOrderItems = orderedArray.map((item) => {
    if (arrayGroupedById[item.id]) {
      return { ...arrayGroupedById[item.id], order: item.order };
    }
    return null;
  });

  const allAvailablePlaylist = playlistsWithOrderItems.filter(
    (item) => item !== null
  );

  const intersectionPlaylists = array.filter((item) =>
    allAvailablePlaylist.every(
      (playlistItem) => item.id !== (playlistItem && playlistItem.id)
    )
  );

  return [...allAvailablePlaylist, ...intersectionPlaylists] as Playlist[];
};

export const filterSongsByParams = (
  songs: Song[],
  groups: string[],
  assignments: string[],
  dateOrder: string,
  isShowLowComments: boolean,
  specifiedDate: SpecifiedDate,
  isRandomize: boolean
) => {
  const filteredSongs = songs.map((item) => {
    const isSongIncludesGroup = groups.some((groupId) =>
      item.groups.includes(groupId)
    );
    const isSongIncludesAssignment = assignments.some(
      (assignmentId) => item.assignment === assignmentId
    );

    if (isEmpty(assignments) && isSongIncludesGroup) {
      return item;
    } else if (isSongIncludesAssignment) {
      return item;
    } else {
      return {} as Song;
    }
  });

  const existingSongs = filteredSongs.filter((item) => item.id);

  if (isShowLowComments) {
    return existingSongs.filter((item) => {
      return +item.commentsNumber <= 2;
    });
  } else if (isRandomize) {
    return shuffle(existingSongs);
  } else if (dateOrder === "specify") {
    const formattedCurrentDate = new Date(
      new Date(specifiedDate.year, specifiedDate.month)
    ).getTime();
    const formattedNextDate = new Date(
      new Date(specifiedDate.year, specifiedDate.month + 1, 0)
    ).getTime();

    return existingSongs.filter((item) => {
      const itemDate = toDateSeconds(item.updatedAt);

      return itemDate >= formattedCurrentDate && itemDate <= formattedNextDate;
    });
  } else {
    return existingSongs.sort((a, b) => {
      if (dateOrder === "new") {
        return compareTimestamps(b.updatedAt, a.updatedAt);
      } else {
        return compareTimestamps(a.updatedAt, b.updatedAt);
      }
    });
  }
};

export const getIsChecked = (value: string, array: string[]) => {
  return array.includes(value);
};

export const forceDownload = (blob: any, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadResource = (url: string, filename: string) => {
  fetch(url, {
    headers: new Headers({
      Origin: url,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      SongService.getFileExtension(url).then((ext) => {
        forceDownload(blobUrl, filename + "." + ext);
      });
    })
    .catch((e) => console.error(e));
};

export const getPermission = (role: string[] = []) => {
  const isSuperAdmin = role.includes("super admin");
  const isAdmin = role.includes("admin");
  const isModerator = role.includes("moderator");

  return isSuperAdmin || isAdmin || isModerator;
};

export const useProgressiveImage = (src: any) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

const getGroupsByType = (groups: Group[], type: "active" | "inactive") => {
  const isActiveType = type === "active";

  return groups
    .filter((group) => {
      if (isActiveType) {
        return group.active;
      } else {
        return !group.active;
      }
    })
    .map((item) => item.id);
};

export const getCombinedGroups = (groups: Group[]): GroupId[] => {
  const activeGroups = getGroupsByType(groups, "active");
  const inactiveGroups = getGroupsByType(groups, "inactive");

  return trimToTen([...activeGroups, ...inactiveGroups]);
};
