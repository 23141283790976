import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
} from '@ionic/react'
import {
  addOutline,
  chatbubblesOutline,
  pause,
  play,
  starOutline,
  starSharp,
} from 'ionicons/icons'
import React, { useLayoutEffect } from 'react'

import { useFavorites } from '../data/store/useFavorites'
import { usePlayer } from '../data/store/usePlayer'
import { usePlaylist } from '../data/store/usePlaylist'
import { useProfile } from '../data/store/useProfile'
import { useSongs } from '../data/store/useSongs'

import { Song } from '../models/Song'
import MediaService from '../services/MediaService'
import useWindowSize from '../util/useWindowSize'
import ArtistButton from './ArtistButton'

interface OwnProps {
  id: string
}

interface SongItemProps extends OwnProps {}

const CompactSongItem: React.FC<SongItemProps> = ({ id }) => {
  const { isFavorite, addFavorite, removeFavorite } = useFavorites()
  const { authenticated, ownerId } = useProfile()
  const { isPaused } = usePlayer()
  const { fetch, shouldFetch, empty } = useSongs()
  const { setNowPlaying, nowPlayingId, queueSong, songs } = usePlaylist()
  const song = useSongs((x) => x.retrieve(id))
  const { width } = useWindowSize()

  const isNowPlaying = id === nowPlayingId ? 'now-playing' : ''
  const isInPlaylist = song && songs.includes(id)
  const songHeaderClass = 'song-item-header ' + isNowPlaying
  const isShouldFetch = shouldFetch(id)
  const mobileWith = width < 500

  const addSongToPlaylist = () => {
    queueSong(id)
  }

  const pauseNow = () => {
    MediaService.please().pause()
  }

  const playSongNow = () => {
    if (!isInPlaylist) {
      queueSong(id)
    }
    if (song) {
      MediaService.please()
        .setSong(song)
        .then(() => {
          MediaService.please().play()
          setNowPlaying(id)
        })
    }
  }

  const renderSongHeader = (song: Song) => {
    return (
      <IonCol>
        <IonLabel>
          <IonButton routerLink={'/song/' + song.id} color="tertiary">
            {song.title}
          </IonButton>
        </IonLabel>

        <IonRow>
          {song.authors &&
            song.authors.map((authorId, index) => (
              <ArtistButton
                key={index}
                artist={song.artists[authorId]}
                variant="mention"
              />
            ))}
        </IonRow>
      </IonCol>
    )
  }

  useLayoutEffect(() => {
    if (!song && isShouldFetch) {
      fetch(id)
    } else if (!song && empty.includes(id)) {
      removeFavorite(ownerId, id)
    }
  }, [empty, fetch, id, isShouldFetch, ownerId, removeFavorite, song])

  if (!song) return <></>

  if (song.privacy === 'member' && !authenticated) {
    return (
      <IonCard className="song-item">
        <IonToolbar className={songHeaderClass} />
        <IonCardContent className="song-item-content">
          The author of this song has marked this as visible to members only.
        </IonCardContent>
        <IonButton fill="outline" routerLink="login">
          login
        </IonButton>
      </IonCard>
    )
  }

  return (
    <IonItem
      color="light"
      lines="inset"
      style={{ border: 1, borderColor: 'grey' }}
    >
      {/* {mobileWith && (
        <IonButtons class="ion-text-center">
          {renderSongHeader(song)}
        </IonButtons>
      )} */}

      <IonButtons slot="start">
        {isNowPlaying ? (
          isPaused ? (
            <IonButton size="large" onClick={playSongNow}>
              <IonIcon
                className="--ion-padding"
                size="large"
                color="dark"
                icon={play}
              />
            </IonButton>
          ) : (
            <IonButton size="large" onClick={pauseNow}>
              <IonIcon
                className="--ion-padding"
                size="large"
                color="dark"
                icon={pause}
              />
            </IonButton>
          )
        ) : (
          <IonButton size="large" onClick={playSongNow}>
            <IonIcon
              className="--ion-padding"
              size="large"
              color="dark"
              icon={play}
            />
          </IonButton>
        )}
      </IonButtons>

      <IonButtons>{renderSongHeader(song)}</IonButtons>

      <IonButtons slot="end">
        {!isInPlaylist && (
          <IonButton size="large" onClick={() => addSongToPlaylist()}>
            <IonIcon
              className="--ion-padding"
              size="large"
              color={'dark'}
              icon={addOutline}
            />
          </IonButton>
        )}

        {ownerId && song.id && (
          <IonButton
            color="dark"
            onClick={() => {
              !isFavorite(ownerId!, song.id)
                ? addFavorite(ownerId!, song.id)
                : removeFavorite(ownerId!, song.id)
            }}
          >
            <IonIcon
              slot="start"
              color={isFavorite(ownerId, song.id) ? 'warning' : undefined}
              icon={!isFavorite(ownerId, song.id) ? starOutline : starSharp}
            />
          </IonButton>
        )}

        {!mobileWith && (
          <>
            <IonButton routerLink={'/song/' + song.id} color="dark">
              <IonIcon icon={chatbubblesOutline} />
            </IonButton>
            <IonAvatar>
              <IonBadge>{song.commentsNumber}</IonBadge>
            </IonAvatar>
          </>
        )}
      </IonButtons>
    </IonItem>
  )
}

export default CompactSongItem
