import { firestore } from "firebase";

export interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

export const BeginningOfTime = firestore.Timestamp.fromDate(new Date(-1));
export const EndOfTime = firestore.Timestamp.fromDate(new Date(-1));
export interface HasTimestamp {
  updatedAt: Timestamp;
}

export interface DocumentRef extends HasTimestamp {
  collection: string;
  id: string;
  authorId: string;
}
export interface Timeline {
  days: Day[];
  cursor: string;
}

export interface Day {
  transmissions: DocumentRef[];
  date: string;
}

export interface SpecifiedDate {
  year: number;
  month: number;
}
