import React, { useEffect, useState } from "react";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import {
  alertOutline,
  informationCircleOutline,
  musicalNoteOutline,
} from "ionicons/icons";

import { useAssignments } from "../data/store/useAssignments";
import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useToasts } from "../data/store/useToasts";
import { useTransmission } from "../data/store/useTransmission";
import { useSongs } from "../data/store/useSongs";
import { ArtistId } from "../models/Artist";

import ArtistSelector from "./ArtistSelector";
import Content from "./Content";
import ConvertingUploader from "./ConvertingUploader";
import HashTagify from "./Hashtagify";
import RequestAccessCard from "./RequestAccessCard";
import SongItem from "./SongItem";

import { usePageTitle } from "../util/sugar";
import "react-dropzone-uploader/dist/styles.css";

interface TransmitProps {}

const Transmit: React.FC<TransmitProps> = () => {
  const { authorId, ownerId } = useProfile();
  const {
    todaysTransmission,
    clearTodayTransmission,
    status,
    isValid,
    setAuthors,
    fetchTodaysTransmission,
    setMedia,
    setDescription,
    setTitle,
    transmit,
    setGroups,
    setAssignment,
    assignment,
  } = useTransmission();
  const [selectedGroup] = useTransmission((x) => x.groups);
  const { assignmentsInGroup, assignments } = useAssignments();
  const { post } = useToasts();
  const { delete: deleteSong, fetch, shouldFetch } = useSongs();
  const { config, groups } = useConfig();
  const membership = useConfig((x) => x.activeMemberships());
  const [authors, setSongAuthors] = useState<ArtistId[]>([]);
  const [addSecondSong, setAddSecondSong] = useState(false);
  const transmitNotice = config && config.transmitNotice;

  usePageTitle("Transmit a Song");

  useEffect(() => {
    if (typeof selectedGroup === "undefined" && membership.length === 1) {
      setGroups([membership[0]]);
    }
  }, [selectedGroup, membership, setGroups]);

  useEffect(() => {
    const assignmentsList =
      selectedGroup && assignments ? assignmentsInGroup(selectedGroup) : [];
    const activeAssignments = assignmentsList.filter(
      (x) => x.status === "active"
    );
    if (activeAssignments.length === 1) {
      setAssignment(activeAssignments[0].id);
    }
    if (activeAssignments.length === 0) {
      setAssignment("");
    }
  }, [selectedGroup, assignmentsInGroup, setAssignment, assignments]);

  useEffect(() => {
    ownerId && status.includes("idle") && fetchTodaysTransmission(ownerId);

    // eslint-disable-next-line
  }, [fetchTodaysTransmission, ownerId, todaysTransmission]);

  useEffect(() => {
    todaysTransmission &&
      shouldFetch(todaysTransmission) &&
      fetch(todaysTransmission, true);
    // eslint-disable-next-line
  }, [todaysTransmission]);

  useEffect(() => {
    if (authorId && !authors.includes(authorId)) {
      setSongAuthors((oldAuthors) => [...oldAuthors, authorId]);
    } else {
      setAuthors(authors);
    }
  }, [authorId, setSongAuthors, authors, setAuthors]);

  if (membership.length === 0) {
    return (
      <Content>{authorId && <RequestAccessCard authorId={authorId} />}</Content>
    );
  }

  return (
    <Content className="chill">
      {(!todaysTransmission || addSecondSong) && ownerId && (
        <IonRow>
          <IonCol sizeMd="6" pushMd="3">
            {transmitNotice && (
              <IonCard color="light">
                <IonCardContent color="medium">
                  <IonItem color="clear">
                    <HashTagify>{transmitNotice}</HashTagify>
                    <IonButtons slot="end">
                      <IonButton
                        color="tertiary"
                        onClick={() => {
                          alert(
                            "Welcome to the song transmitter! If you're submitting for an assignment, make sure to select the assignment you're submitting for, as well as the group you are in. (Audio uploads only)"
                          );
                        }}
                      >
                        <IonIcon icon={informationCircleOutline} />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            )}

            <IonList className="inner-chill">
              {authorId && authors.includes(authorId) && (
                <ArtistSelector
                  placeholder={"Collaborators"}
                  fieldName={"Authors"}
                  members={authors}
                  setMembers={setSongAuthors}
                />
              )}

              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Title
                </IonLabel>
                <IonInput
                  spellCheck={false}
                  autocapitalize="off"
                  onIonChange={(e) => setTitle(e.detail.value!)}
                  required
                />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Description
                </IonLabel>
                <IonTextarea
                  rows={5}
                  maxlength={2000}
                  autoGrow
                  onIonChange={(e) => setDescription(e.detail.value!)}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Media
                </IonLabel>
                <ConvertingUploader
                  collection={"songs"}
                  onComplete={(media) => {
                    const pathParts = media.src.split(".");
                    const extension =
                      pathParts[pathParts.length - 1].toLowerCase();
                    if (
                      extension === "aif" ||
                      extension === "aaif" ||
                      extension === "aiif" ||
                      extension === "wav" ||
                      extension === "flac"
                    ) {
                      alert("UNSUPPORTED FILE TYPE");
                      document.location.reload();
                      return;
                    }
                    if (media) setMedia(media);
                  }}
                />
              </IonItem>

              {membership.length > 0 && (
                <IonItem lines="none">
                  <IonLabel
                    position="stacked"
                    color="primary"
                    style={{ flex: "1 0 auto" }}
                  >
                    Group
                  </IonLabel>
                  <IonRow
                    className="ion-justify-content-end ion-wrap"
                    style={{ margin: "0 -5px" }}
                  >
                    {membership.map((groupId) => {
                      const { title } = groups[groupId] || { title: groupId };
                      return (
                        <IonBadge
                          key={groupId}
                          style={{ cursor: "pointer", margin: 5 }}
                          onClick={() => setGroups([groupId])}
                          color={
                            selectedGroup === groupId ? "favorite" : "medium"
                          }
                        >
                          <IonLabel>
                            <h4>{title}</h4>
                          </IonLabel>
                        </IonBadge>
                      );
                    })}
                  </IonRow>
                </IonItem>
              )}

              {selectedGroup && assignmentsInGroup(selectedGroup).length > 0 && (
                <>
                  <IonItem lines="none">
                    <IonLabel
                      position="stacked"
                      color="primary"
                      style={{ flex: "1 0 auto" }}
                    >
                      Assignment
                    </IonLabel>
                    <IonRow
                      className="ion-justify-content-end ion-wrap"
                      style={{ margin: "0 -5px" }}
                    >
                      {assignmentsInGroup(selectedGroup).map((assign) =>
                        assign.title ? (
                          <IonBadge
                            key={assign.id}
                            style={{ cursor: "pointer", margin: 5 }}
                            onClick={() => setAssignment(assign.id)}
                            color={
                              assign.id === assignment ? "favorite" : "medium"
                            }
                          >
                            <IonLabel>
                              <h4>{assign.title}</h4>
                            </IonLabel>
                          </IonBadge>
                        ) : (
                          <div />
                        )
                      )}
                    </IonRow>
                  </IonItem>

                  {!assignment && (
                    <IonItem lines="none" className="ion-text-center">
                      <IonText
                        color="danger"
                        style={{ margin: "-5px auto 5px" }}
                      >
                        <small>
                          Please select which assignment this is for before
                          submitting
                        </small>
                      </IonText>
                    </IonItem>
                  )}
                </>
              )}
              <IonItemDivider color="light" />
            </IonList>
            <IonRow>
              <IonCol>
                <IonButton
                  disabled={
                    !isValid() ||
                    status === "transmitting" ||
                    typeof selectedGroup === "undefined" ||
                    !assignment
                  }
                  color={isValid() ? "favorite" : "primary"}
                  expand="block"
                  onClick={() => {
                    post({
                      id: "new-song",
                      message: "Song Transmitting",
                      icon: musicalNoteOutline,
                    });
                    transmit(ownerId);
                    setAddSecondSong(false);
                  }}
                >
                  Transmit
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      )}

      {todaysTransmission && !addSecondSong && (
        <>
          <IonRow>
            <IonCol sizeMd="6" pushMd="3">
              <SongItem id={todaysTransmission} />
              <IonButton
                color="danger"
                expand="block"
                onClick={() => {
                  deleteSong(todaysTransmission);
                  clearTodayTransmission();
                  post({
                    id: `new-song ${todaysTransmission}`,
                    message: "Song Revoked",
                    icon: alertOutline,
                  });
                }}
              >
                Revoke
              </IonButton>
            </IonCol>
          </IonRow>
          <IonButton
            expand="full"
            color="secondary"
            onClick={() => setAddSecondSong(true)}
          >
            Submit another song
          </IonButton>
        </>
      )}
    </Content>
  );
};

export default Transmit;
