import firebase, { firestore } from "firebase/app";
import { ArtistId } from "../models/Artist";
import {
  Conversation,
  ConversationId,
  FreshMessageNode,
} from "../models/Message";
import { OwnerId } from "../models/User";
import { ArtistService } from "./ArtistService";
import { NotificationService } from "./NotificationService";
import ObserverService from "./ObserverService";

export class ConversationService {
  static transmit = (threadId: string, messageNode: FreshMessageNode) => {
    const db = firebase.firestore();
    const messageWithTimeStamp = {
      ...messageNode,
      timestamp: new Date().getTime().toString(),
    };

    const serverThread = db.collection("conversations").doc(threadId);
    const senderId = messageWithTimeStamp.authorId;
    //Initialize Transaction
    return db.runTransaction(function (transaction) {
      return transaction.get(serverThread).then(function (conversationDoc) {
        const conversation =
          ConversationService.stronglyTypeConversationDoc(conversationDoc);
        if (conversation === undefined) {
          return;
        }
        // Add the message
        transaction.update(serverThread, {
          messages:
            firebase.firestore.FieldValue.arrayUnion(messageWithTimeStamp),
          typing: firebase.firestore.FieldValue.arrayRemove(senderId),
        });
        // Notify Conversation members
        for (const member of conversation.members) {
          if (member !== senderId) {
            const link = "conversations?thread=" + conversation.id;
            NotificationService.post({
              from: senderId,
              text: messageWithTimeStamp.text,
              to: member,
              link,
            });
          }
        }
      });
    });
  };

  static transmitConversation = (
    title: string,
    members: ArtistId[],
    messageNode: FreshMessageNode
  ): Promise<Conversation> => {
    return new Promise<Conversation>(async (resolve, reject) => {
      var db = firebase.firestore();
      const artists = await Promise.all(
        members.map(async (artistId) => ArtistService.fetch(artistId))
      );
      const owners = artists.map((x) => x.ownerId);

      let conversation = {
        messages: [messageNode],
        members,
        title,
        owners,
      } as Conversation;
      console.log(conversation);
      const starterId = conversation.messages[0].authorId;
      let conversationWithTimestamp = {
        ...conversation,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      db.collection("conversations")
        .add(conversationWithTimestamp)
        .then(async (serverConversationRef) => {
          const serverConversation = await serverConversationRef.get();

          const conversationToAdd =
            ConversationService.stronglyTypeConversationDoc(serverConversation);
          for (const member of members) {
            if (member !== starterId)
              NotificationService.post({
                from: starterId,
                text: "You were added to a conversation called " + title,
                to: member,
                link: "conversations?thread=" + serverConversation.id,
              });
          }
          resolve(conversationToAdd);
        })
        .catch(reject);
    });
  };
  static leaveConversation = (
    ownerId: string,
    authorId: string,
    conversationId: ConversationId
  ): Promise<void> => {
    var db = firebase.firestore();
    return ConversationService.transmit(conversationId, {
      authorId,
      ownerId,
      text: "Has Left the conversation 🍃",
    }).then(() => {
      db.collection("conversations")
        .doc(conversationId)
        .update({
          owners: firebase.firestore.FieldValue.arrayRemove(ownerId),
          members: firebase.firestore.FieldValue.arrayRemove(authorId),
        });
    });
  };

  static fetch = (id: ConversationId): Promise<Conversation> => {
    return new Promise<Conversation>((resolve, reject) => {
      var db = firebase.firestore();
      db.collection("conversations")
        .doc(id)
        .get()
        .then((conversationDoc) => {
          resolve(
            ConversationService.stronglyTypeConversationDoc(conversationDoc)
          );
        })
        .catch(reject);
    });
  };

  static stronglyTypeConversationDoc = (
    convoDoc:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | firestore.DocumentSnapshot<firestore.DocumentData>
  ) => {
    return { ...convoDoc.data(), id: convoDoc.id } as Conversation;
  };

  static observe = (
    ownerId: OwnerId,
    onConversationMessage: (message: Conversation) => void
  ) => {
    var db = firebase.firestore();
    console.log("start observing conversations" + ownerId);
    if (ObserverService.please().exist("conversations/" + ownerId)) {
      console.log("observing already");
      return;
    }

    const watcher = db
      .collection("conversations")
      .where("owners", "array-contains", ownerId)
      .orderBy("updatedAt", "desc")
      .onSnapshot((conversationsDoc) => {
        for (let serverConversationDoc of conversationsDoc.docs) {
          let conversation = ConversationService.stronglyTypeConversationDoc(
            serverConversationDoc
          );
          onConversationMessage(conversation);
        }
      });
    ObserverService.please().add("conversations", watcher);
  };

  static stopObserving = () => {
    console.log("Stop observing conversations");
    ObserverService.please().remove("conversations");
  };
}
